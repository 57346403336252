import './App.css';
import { BrowserRouter } from 'react-router-dom';
import React, { useState, useEffect, Suspense } from 'react';
import AllRoutes from './routes/Routes';
import { LoaderResponse } from './components/Loader';
import { COLORS } from './constants/ColorsData';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulez un chargement initial (par exemple, données globales, configuration, etc.)
    const timer = setTimeout(() => setIsLoading(false), 2000); // Temps simulé
    return () => clearTimeout(timer); // Nettoyez le timer pour éviter les fuites mémoire
  }, []);

  // if (isLoading) {
  //   const color = COLORS.jauneClaire
  //   return (
  //     <div style={{
  //       display: 'flex',
  //       flex: 1, justifyContent: 'center', alignItems: 'center',
  //       height: '100vh', flexDirection:
  //         'column',
  //       backgroundImage: `url("/images/fond/sso_main_bg.svg")`, backgroundRepeat: 'no-repeat', backgroundSize: "cover"
  //     }}>
  //       <LoaderResponse colorValue={color} w={130} h={130} />
  //       <p style={{ color: color }}>Chargement en cours...</p>
  //     </div>
  //   )
  // }

  return (
    <BrowserRouter>
      <Suspense fallback={<div className="loader">Chargement des routes...</div>}>
        <AllRoutes />
      </Suspense>
    </BrowserRouter>
  );
};

export default App;

import React, { FC, useEffect, useState } from "react";
import { CompanyDescValueType, SenderData, SenderSuggestionKeyType, SenderSuggestionType } from "../../type/UserDataEvent";
import { LoaderResponse } from "../../components/Loader";
import PageGenerationProps from "../../interface/PageInterface";
import { SENDER_SAVING_STATE, SENDER_KEY, DOC_ID_KEY, DEST_SAVING_STATE, V2_SENDER_KEY, RECIPIENT_KEY, PROMPT2LLM_KEY } from "../../constants/StoreKey";
import { updateRecipientOrSenderData } from "../../services/UserServices";

import "../../styles/AureliaPage.css"
import { COLORS } from "../../constants/ColorsData";
import { encryptData } from "../../utils/util_function";
import ComboBox from "../../components/ComboBox";
import { navigate2 } from "../../utils/redirection";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import WSnackbar from "../../components/Widget/WSnackbar";
import { truncate } from "fs";
import { FIRST_PAGE } from "../../constants/textVariable";

const Sender:FC<PageGenerationProps> = ({userid, setPageNameToLoad, creditValue}) => {
    const user_id = userid
    const [requestStatus, setRequestStatus] = useState<boolean>(false);
    const [mailValidation, setMailValidation] = useState<boolean>(true);
    const [savingOk, setSavingOk] = useState<boolean>(false);
    const [loaderState, setLoaderState] = useState<boolean>(true);
    const [loaderPage, setLoaderPage] = useState<boolean>(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [targetJobCount, setTargetJobCount] = useState<number>(0);
    const [targetJobList, setTargetJobList] = useState<Array<string>>([])
    const [targetJobValue, setTargetJobValue] = useState("");
    const [senderValueByTargetJob, setSenderValueByTargetJob] = useState("");
    const [selectedSuggestionKey, setSelectedSuggestionKey] = useState<SenderSuggestionKeyType>("firstname");
    const [hidePlaceholder, setHidePlaceholder] = useState(false);
    const [emptyField, setEmptyField] = useState({
        state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, state7: false, state8: false, state9: false
    });
    const [suggestionIsClicked, setSuggestionIsClicked] = useState({
        state1: true, state2: true, state3: true, state4: true, state5: true, state6: true, state7: true, state8: true, state9: true
    });

    const [userFormData, setUserFormData] = useState<SenderData>({
        firstname: "",
        lastname: "",
        email_adress: "",
        job: "",
        companyName: "",
        companyActivity: "",
        serviceDesc: [],
        ref_companyActivity: "",
        ref_company_name: ""
    });

    const [tempCompanyValues, setTempCompanyValues] = useState<Array<CompanyDescValueType>>([])

    const [inputValue, setInputValue] = useState<string>("");
    const [suggestions, setSuggestions] = useState<SenderSuggestionType>({
        firstname: [],
        lastname: [],
        destination_email: [],
        senderJob: [],
        senderCompanyName: [],
        senderCompanyActivity: [],
        senderCompanyValue: [],
        companyRefDomain: [],
        companyRefList: []
    });

    const CACHE_KEY = "textCache";

    // Charger les suggestions du cache au chargement du composant
    useEffect(() => {
        const cachedTexts = localStorage.getItem(CACHE_KEY);
        if (cachedTexts) {
            setSuggestions(JSON.parse(cachedTexts));
        }
    }, []);

    // Sauvegarder la nouvelle entrée dans le cache
    const updateCache = (sender: any) => {
        // let cachedTexts: string[] = suggestions;
        let cachedTexts: SenderSuggestionType = {
            firstname: [],
            lastname: [],
            destination_email: [],
            senderJob: [],
            senderCompanyName: [],
            senderCompanyActivity: [],
            senderCompanyValue: [],
            companyRefDomain: [],
            companyRefList: []
        }

        // Ajouter la nouvelle entrée si elle n'existe pas déjà
        if (!cachedTexts["firstname"].includes(sender.firstname)) {
            cachedTexts["firstname"].push(sender.firstname);
        }
        if (!cachedTexts["lastname"].includes(sender.lastname)) {
            cachedTexts["lastname"].push(sender.lastname);
        }
        if (!cachedTexts["destination_email"].includes(sender.destination_email)) {
            cachedTexts["destination_email"].push(sender.destination_email);
        }
        if (!cachedTexts["senderCompanyActivity"].includes([sender.senderCompanyActivity].join(", "))) {
            cachedTexts["senderCompanyActivity"].push([sender.senderCompanyActivity].join(", "));
        }
        if (!cachedTexts["senderCompanyName"].includes(sender.senderCompanyName)) {
            cachedTexts["senderCompanyName"].push(sender.senderCompanyName);
        }
        if (!cachedTexts["senderCompanyValue"].includes([sender.senderCompanyValue].join(", "))) {
            cachedTexts["senderCompanyValue"].push([sender.senderCompanyValue].join(", "));
        }
        if (!cachedTexts["companyRefDomain"].includes(sender.senderCompanyRef.companyRefDomain)) {
            cachedTexts["companyRefDomain"].push(sender.senderCompanyRef.companyRefDomain);
        }
        if (!cachedTexts["companyRefList"].includes([sender.senderCompanyRef.companyRefList].join(", "))) {
            cachedTexts["companyRefList"].push([sender.senderCompanyRef.companyRefList].join(", "));
        }
        localStorage.setItem(CACHE_KEY, JSON.stringify(cachedTexts));
        // setSuggestions(cachedTexts[key]); // Mettre à jour les suggestions localement
    };

    // Gérer la soumission (simule la sauvegarde du texte)
    const handleSubmit = (fieldValue: any) => {
        if (fieldValue) {
            updateCache(fieldValue);
            setInputValue(""); // Réinitialiser le champ de texte après soumission
        }
    };

    // Gérer les modifications du champ de texte
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    };

    // Filtrer les suggestions basées sur l'entrée actuelle
    const filteredSuggestions = suggestions['senderCompanyValue'].filter((text_list) =>
        // text.toLowerCase().includes(inputValue.toLowerCase())
        text_list.includes(inputValue.toLowerCase())
    );
    
    const goToNextPage = () => {
        // setPageNameToLoad("Mail")
        // if(localStorage.getItem(DEST_SAVING_STATE) === null){
        //     Redirect2NewPage("Aurélia", "Dashboard/Destinataire")
        // }else Redirect2NewPage("Aurélia", `Dashboard/Mail`)
        
        // Vérifier si on a déjà configurer les informations sur le destinataire, l'émetteur, et le mail
        if(user_id) {
            if(localStorage.getItem(DEST_SAVING_STATE) === null){
                return navigate2("Destinataire", user_id)
            }else if(localStorage.getItem(PROMPT2LLM_KEY) === null){
                return navigate2("Mail", user_id)
            }
        }
    }

    const updateFirstName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, firstname: val.target.value }));
    }

    const updateLastName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, lastname: val.target.value }));
    }

    const updateProfession = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, job: val.target.value }));
    }

    const updateCompanyName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        if(val !== undefined) setUserFormData(prevState => ({ ...prevState, companyName: val.target.value }));
    }
    const updateCompanyActivity = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        if(val !== undefined) setUserFormData(prevState => ({ ...prevState, companyActivity: val.target.value }));
    }

    // Single company value
    const updateServiceDescription = (val: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, 
            serviceDesc: [val.target.value]
        }));
    }

    const updateRefCompanyActivity = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, ref_companyActivity: val.target.value }));
    }
    
    const updateRefCompanyName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, ref_company_name: val.target.value }));
    }

    const updateEmailAdress = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, email_adress: val.target.value }));
    }

    const updateFinalCompanyValues = (companyValue: string) => {
        setSenderValueByTargetJob(companyValue)

        let tempArray: Array<CompanyDescValueType> = tempCompanyValues
        setInputValue(companyValue)
        if(targetJobCount > 1){ // Un poste trouvé ie plusieurs postes choisies dans déstinataire
            tempCompanyValues.forEach((element, index) => {
                if(targetJobValue === element.target_job){
                    tempArray[index].desc_value = companyValue
                }
            });
        } else {
            // un seul poste choisi
            tempArray = [{
                target_job: targetJobValue,
                desc_value: companyValue
            }]
        }
        
        setTempCompanyValues(tempArray)
        setUserFormData(prevState => ({ ...prevState, 
            serviceDesc: convertCompanyValues2TextList(tempArray)
        }));
    }

    // eslint-disable-next-line
    const getCompanyValueByJob = (job: string) => {
        const found = tempCompanyValues.find((element) => element.target_job === job)
        
        return found ? found.desc_value : ""
    }

    const convertCompanyValues2TextList = (data: Array<CompanyDescValueType>) => {
        let newList: Array<string> = []

        data.forEach(item => {
            newList.push(item.desc_value)
        });

        return newList 
    }

    const  validateEmail = (email: string) => {
        // Expression régulière pour valider une adresse e-mail
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        // Vérifier si l'e-mail correspond au format regex
        const isValid = emailRegex.test(email);
    
        // Si l'e-mail est invalide, colorer le champ d'entrée en rouge
        if (!isValid) {
            setMailValidation(false)
        } else {
            // Sinon, réinitialiser la couleur du champ d'entrée
            setMailValidation(true)
        }
    
        // Retourner si l'e-mail est valide ou non
        return isValid;
    }
    

    const saveUserDataToLocal = () => {
        console.log(userFormData)
        if (!userFormData.firstname){
            setEmptyField(
                prevState => ({...prevState, state1: true})
            )
            return
        }else setEmptyField(prevState => ({...prevState, state1: false}))

        if(!userFormData.lastname) {
            setEmptyField(
                prevState => ({...prevState, state2: true})
            )
            return;
        } else setEmptyField(prevState => ({...prevState, state2: false}))

        if (!userFormData.email_adress){
            setEmptyField(
                prevState => ({...prevState, state3: true})
            )
            return
        }else {
            if(!validateEmail(userFormData.email_adress)) {
                return
            }
            setEmptyField(prevState => ({...prevState, state3: false}))
        }

        if(!userFormData.job) {
            setEmptyField(
                prevState => ({...prevState, state4: true})
            )
            return;
        } else setEmptyField(prevState => ({...prevState, state4: false}))

        if (!userFormData.companyName){
            setEmptyField(
                prevState => ({...prevState, state5: true})
            )
            return
        }else setEmptyField(prevState => ({...prevState, state5: false}))

        if(!userFormData.companyActivity) {
            setEmptyField(
                prevState => ({...prevState, state6: true})
            )
            return;
        } else setEmptyField(prevState => ({...prevState, state6: false}))

        // Vérification du champ de propositions de valeur
        // const userFormatDataSenderServiceList = targetJobCount <= 1 ? userFormData.serviceDesc : convertCompanyValues2TextList(tempCompanyValues)
        const userFormatDataSenderServiceList = convertCompanyValues2TextList(tempCompanyValues)
        
        // Charger la liste de poste
        const ls_dest_data = localStorage.getItem(RECIPIENT_KEY)
        let jobCount = 0

        if(ls_dest_data){
            const dest_data_value = JSON.parse(ls_dest_data)
            if(dest_data_value){
                jobCount = dest_data_value['targetJob'].length
            }
        }
        console.info(userFormatDataSenderServiceList.length, jobCount)
        // if(userFormatDataSenderServiceList.length !== jobCount) {
        if(!userFormData.serviceDesc){
            setEmptyField(
                prevState => ({...prevState, state7: true})
            )
            return;
        } else setEmptyField(prevState => ({...prevState, state7: false}))
        
        // if(!userFormData.ref_companyActivity) {
        //     setEmptyField(
        //         prevState => ({...prevState, state8: true})
        //     )
        //     return;
        // } else setEmptyField(prevState => ({...prevState, state8: false}))

        // if(!userFormData.ref_company_name) {
        //     setEmptyField(
        //         prevState => ({...prevState, state9: true})
        //     )
        //     return;
        // } else setEmptyField(prevState => ({...prevState, state9: false}))

        // Lancement du sauvegarde
        if(user_id){
            setRequestStatus(true)
            
            const doc_id = localStorage.getItem(DOC_ID_KEY)
            const sender = {
                "firstname": userFormData.firstname,
                "lastname": userFormData.lastname,
                "destination_email": userFormData.email_adress,
                "senderJob": userFormData.job,
                "senderCompanyName": userFormData.companyName,
                "senderCompanyValue": userFormatDataSenderServiceList,
                "senderCompanyActivity": [userFormData.companyActivity],
                "senderCompanyRef": {
                    "companyRefDomain": userFormData.ref_companyActivity,
                    "companyRefList": [userFormData.ref_company_name]
                }
            }
            
            encryptData(V2_SENDER_KEY, sender)
            localStorage.setItem(SENDER_KEY, JSON.stringify(userFormData))

            if(localStorage.getItem(SENDER_KEY)) {
                updateRecipientOrSenderData({
                    "_id": doc_id,
                    "user_id": user_id,
                    "cle": "sender",
                    "body" : userFormData
                }).then((rep: any) => {
                    if(doc_id === null || doc_id === undefined) localStorage.setItem(DOC_ID_KEY, rep.body._id)

                    setRequestStatus(false)
                    setSavingOk(true)
                    localStorage.setItem(SENDER_SAVING_STATE, String(true))

                    // Sauver en cache les textes
                    handleSubmit(sender)

                    setOpenSnackbar(true)
                    // goToNextPage()
                }).catch((e) => {
                    setRequestStatus(false)
                    console.error(e)
                })
            }
        }
    }

    useEffect(() => {
        // Destinataire
        let recipient_data_retrieve = localStorage.getItem(RECIPIENT_KEY)
        
        if(recipient_data_retrieve) {
            let recip_json = JSON.parse(recipient_data_retrieve)
            
            if(recip_json) {
                const targetJobVal = recip_json['targetJob']
                if(targetJobVal) {
                    if(targetJobVal.length <= 1 ) setTargetJobValue(targetJobVal[0])
                    else setTargetJobCount(targetJobVal.length)
                    setTargetJobList(targetJobVal)
                    setLoaderState(false)
                }else {
                    setLoaderState(false)
                }
            }else {
                setLoaderState(false)
            }
        } else {
            setLoaderState(false)
            navigate2(FIRST_PAGE, user_id)
        }
    }, [user_id])

    useEffect(() => {
        let recipient_data_retrieve = localStorage.getItem(RECIPIENT_KEY)
        // Emetteur
        let sender_data_retrieve = localStorage.getItem(SENDER_KEY)
        
        if(sender_data_retrieve) {
            let myJson = JSON.parse(sender_data_retrieve)
            // console.log(myJson)
            setUserFormData({
                firstname: myJson["firstname"],
                lastname: myJson["lastname"],
                email_adress: myJson["email_adress"] ? myJson["email_adress"] : myJson["destination_email"],
                job: myJson["job"] ? myJson["job"] : myJson["senderJob"],
                companyName: myJson["companyName"] ? myJson["companyName"] : myJson["senderCompanyName"],
                companyActivity: myJson["companyActivity"] ? myJson["companyActivity"] : Array(myJson["senderCompanyActivity"]).join(", "),
                serviceDesc: myJson["serviceDesc"] ? myJson["serviceDesc"] : myJson["senderCompanyValue"],
                ref_companyActivity: myJson["ref_companyActivity"] ? myJson["ref_companyActivity"] : myJson["senderCompanyRef"]["companyRefDomain"],
                ref_company_name: myJson["ref_company_name"] ? myJson["ref_company_name"] : Array(myJson["senderCompanyRef"]["companyRefList"]).join(", ")
            })
            setSavingOk(Boolean(localStorage.getItem(SENDER_SAVING_STATE)))
            
            // Si les données du destinataire ont été déjà completé
            if(recipient_data_retrieve) {
                let recip_json = JSON.parse(recipient_data_retrieve)
                
                if(recip_json && recip_json['targetJob']) {
                    const TARGETJOB = recip_json['targetJob']

                    // Dans le cas où on a plusieurs postes choisi dans destinaire
                    if (TARGETJOB.length > 2) {
                        let tempValues: Array<CompanyDescValueType> = []
                        TARGETJOB.forEach((jobItem: string, index: number) => {
                            // Parcourir chaque post et sa description associé
                            tempValues.push({
                                target_job: jobItem,
                                desc_value: myJson ? myJson['serviceDesc'][index] : ""
                            })
                        })
                        setTempCompanyValues(tempValues)
                    } else {
                        setTargetJobValue(TARGETJOB[0])
                        
                        const one_service_value = myJson["serviceDesc"] ? myJson["serviceDesc"] : myJson["senderCompanyValue"]
                        
                        setSenderValueByTargetJob(one_service_value[0])
                    }
                }
            }
        }
    }, [])

    useEffect(() => {
        if(tempCompanyValues.length > 1){
            const current_desc = tempCompanyValues.find(element => element.target_job === targetJobValue)
            setSenderValueByTargetJob(
                current_desc ? current_desc.desc_value : ""
            )
        }
    }, [targetJobValue, tempCompanyValues])
    
    useEffect(() => {
        setTimeout(() => {
            setLoaderPage(false)
        }, 2000); // Délai minimal
    }, [])


    return (
        <>
            {
                loaderPage ? (
                    <div className="dest-form-input-left" style={{ justifyContent: "center", alignItems: "center" }}>
                        <LoaderResponse colorValue={COLORS.jauneClaire} w={130} h={130} />
                        <p style={{ color: COLORS.jauneClaire }}>Chargement en cours...</p>
                    </div>
                ) : (
                    <div>
                        <div id="form-input" className="scroll">
                            <div className="col1">
                                <div className="row" style={{border: emptyField.state1 ? "solid 1px red" : ""}}>
                                    <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                        <TextField id="outlined-basic" label="Prénom" variant="outlined" fullWidth multiline rows={1} 
                                            style={{color: COLORS.grisSombre}}
                                            className={emptyField.state1 ? "error-field input_text" : "no-error input_text"}
                                            defaultValue={userFormData.firstname}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                updateFirstName(event)
                                            }}
                                        />
                                    </Box>
                                </div>
                                
                                <div className="row" style={{border: emptyField.state2 ? "solid 1px red" : ""}}>
                                    <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                        <TextField id="outlined-basic" label="Nom" variant="outlined" fullWidth multiline rows={1} 
                                            style={{color: COLORS.grisSombre}}
                                            className={emptyField.state2 ? "error-field input_text" : "no-error input_text"}
                                            defaultValue={userFormData.lastname}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                updateLastName(event)
                                            }}
                                        />
                                    </Box>
                                </div>
                            </div>

                            <div className="row" style={{border: emptyField.state3 ? "solid 1px red" : ""}}>
                                <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    <TextField id="outlined-basic" label="Email" variant="outlined" fullWidth multiline rows={1} 
                                        style={{color: COLORS.grisSombre}}
                                        className={emptyField.state3 ? "error-field input_text" : "no-error input_text"}
                                        defaultValue={userFormData.email_adress}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            updateEmailAdress(event)
                                        }}
                                    />
                                </Box>
                            </div>

                            <div className="row" style={{border: emptyField.state4 ? "solid 1px red" : ""}}>
                                <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    <TextField id="outlined-basic" label="Profession" variant="outlined" fullWidth multiline rows={1} 
                                        style={{color: COLORS.grisSombre}}
                                        defaultValue={userFormData.job}
                                        className={emptyField.state4 ? "error-field input_text" : "no-error input_text"}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            updateProfession(event)
                                        }}
                                    />
                                </Box>
                            </div>

                            <div className="row" style={{border: emptyField.state5 ? "solid 1px red" : ""}}>
                                <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    <TextField id="outlined-basic" label="Nom de l'entreprise" variant="outlined" fullWidth multiline rows={1} 
                                        style={{color: COLORS.grisSombre}}
                                        className={emptyField.state5 ? "error-field input_text" : "no-error input_text"}
                                        defaultValue={userFormData.companyName}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            updateCompanyName(event)
                                        }}
                                    />
                                </Box>
                            </div>
                            
                            <div className="row" style={{border: emptyField.state6 ? "solid 1px red" : ""}}>
                                <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    <TextField id="outlined-basic" label="Description de l’activité de l’entreprise" variant="outlined" fullWidth multiline rows={1} 
                                        style={{color: COLORS.grisSombre}}
                                        className={emptyField.state6 ? "error-field input_text" : "no-error input_text"}
                                        defaultValue={userFormData.companyActivity}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            updateCompanyActivity(event)
                                        }}
                                    />
                                </Box>
                            </div>

                            <div className="row" style={{border: emptyField.state7 ? "solid 1px red" : ""}}>
                                <Box className="box-form-desc" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    {
                                        loaderState ? (
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                <LoaderResponse colorValue={COLORS.jauneClaire} />
                                            </div>
                                        ) : ( 
                                            // Si le poste cible choisi est inférieur ou égale à 1
                                            targetJobCount <= 1 ? (
                                                <TextField 
                                                    id="outlined-multiline-static"
                                                    label="Description de la proposition de valeur"
                                                    fullWidth
                                                    multiline
                                                    rows={2}
                                                    defaultValue={userFormData.serviceDesc[0] ? userFormData.serviceDesc[0] : senderValueByTargetJob}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        updateFinalCompanyValues(event.target.value)
                                                        setInputValue(event.target.value)
                                                    }}
                                                    className={emptyField.state7 ? "error-field input_text" : "no-error input_text"}
                                                />
                                            ) : (
                                                <div>
                                                    <ComboBox 
                                                        data={""} 
                                                        getOptionIndex={setTargetJobValue}
                                                        OptionData={[{
                                                            "title": "Poste cible",
                                                            "content" : targetJobList
                                                        }]} />
                                                    <div className="mail-prep-right-text">
                                                        <textarea 
                                                            disabled={targetJobValue ? false : true}
                                                            className={"textarea-as-input"}
                                                            value={senderValueByTargetJob}
                                                            onChange={(val) => updateFinalCompanyValues(val.target.value)}
                                                            placeholder={"Veuillez saisir les propositions de valeur associées à " + targetJobValue}></textarea>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    }
                                </Box>
                            </div>
                            
                            {/* <div>
                                <ComboBox 
                                    data={""} 
                                    getOptionIndex={setTargetJobValue}
                                    OptionData={[{
                                        "title": "Poste cible",
                                        "content" : targetJobList
                                    }]} />
                                <div className="mail-prep-right-text">
                                    <textarea 
                                        disabled={targetJobValue ? false : true}
                                        className={"textarea-as-input"}
                                        value={senderValueByTargetJob}
                                        onChange={(val) => updateFinalCompanyValues(val.target.value)}
                                        placeholder={"Veuillez saisir les propositions de valeur associées à " + targetJobValue}></textarea>
                                </div>
                            </div> */}

                            <div className="col1">
                                <div className="row" style={{border: emptyField.state8 ? "solid 1px red" : ""}}>
                                    <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                        <TextField id="outlined-basic" label="Réf (Optionnel) : domaine d'activité des entreprises" variant="outlined" fullWidth multiline rows={1} 
                                            style={{color: COLORS.grisSombre}}
                                            className={emptyField.state8 ? "error-field input_text" : "no-error input_text"}
                                            defaultValue={userFormData.ref_companyActivity}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                updateRefCompanyActivity(event)
                                            }}
                                        />
                                    </Box>
                                </div>
                                
                                <div className="row" style={{border: emptyField.state9 ? "solid 1px red" : ""}}>
                                    <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                        <TextField id="outlined-basic" label="Réf (Optionnel) : nom de chaque entreprise (Ex: Entreprise 1, Entreprise 2,...)" variant="outlined" fullWidth multiline rows={1} 
                                            style={{color: COLORS.grisSombre}}
                                            className={emptyField.state9 ? "error-field input_text" : "no-error input_text"}
                                            defaultValue={userFormData.ref_company_name}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                updateRefCompanyName(event)
                                            }}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <div id="form-btn-container">
                            {
                                requestStatus ? (
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <LoaderResponse colorValue={COLORS.jauneClaire} />
                                        <p style={{color: 'black'}}>Enregistrement en cours...</p>
                                    </div>
                                ) : (
                                    <button id="form-btn" onClick={saveUserDataToLocal}>{savingOk ? "Suivant" : "Enregistrer"}</button>
                                )
                            }
                            { openSnackbar && <WSnackbar OPEN={openSnackbar} MESSAGE="Informations sur l'émetteur validées et enregistrées" redirectFunction={goToNextPage} />}
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Sender

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { COLORS } from '../../constants/ColorsData';
import { JsxElement } from 'typescript';

interface State extends SnackbarOrigin {
  open: boolean;
}

interface ISnackbar {
    OPEN: boolean;
    MESSAGE: string;
    VERTICAL?: "bottom" | "top";
    HORIZONTAL?: "left" | "right";
    redirectFunction? : () => void;
    BUTTON?: JsxElement;
    BUTTON_CLICKED?: () => void;
    TIME_DELAY?: number;
}

const PositionedSnackbar: React.FC<ISnackbar> = ({
    OPEN=false, 
    MESSAGE="", 
    VERTICAL='bottom', 
    HORIZONTAL="right", 
    redirectFunction = () => {},
    TIME_DELAY=2000
}) => {
  const [state, setState] = React.useState<State>({
    open: OPEN,
    vertical: VERTICAL,
    horizontal: HORIZONTAL,
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
    redirectFunction()
  };
  React.useEffect(() => {
    console.log("open: ", OPEN)
  }, [OPEN])

  return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        autoHideDuration={TIME_DELAY}
        message={MESSAGE}
        key={vertical + horizontal}
        style={{
            color: "white",
            background: COLORS.jauneSombre
        }}
      />
  );
}

export default PositionedSnackbar
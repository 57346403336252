import React from "react";
import { useParams } from "react-router-dom";

import "../styles/Information.css"
import { MESSAGE, MODEL_TITLE } from "../constants/ArrayData";
import { Redirect2NewPage } from "../Helpers/Redirect2";
import { navigate2 } from "../utils/redirection";

const GenerateInformation = () => {

    const params = useParams();
    const llm_name = params.model_name
    const index = MODEL_TITLE.indexOf(String(llm_name))
    const check_bg_icon = "/images/icone/info/checked_bg.png"
    const user_id = localStorage.getItem('userID')

    if(index === -1) {
        window.location.replace('*');
    }
    const text = MESSAGE[index]

    return (
        <div className="info-content-container">
            <h3 style={{margin: 15}}>Tâche effectuée !</h3>
            <img src={check_bg_icon} alt="" style={{width: 90, height: 90}} />
            <p id="text-p">{text}</p>
            <button className="info-btn-2-home" onClick={()  => {
                    if(user_id) {
                        window.close()
                        // Redirect2NewPage("Aurélia", `Dashboard/Suivi`)
                        navigate2('CampaignHistory', user_id)
                    } else window.close()
                }}>
                Super ! Merci
            </button>
        </div>
    )
}

export default GenerateInformation
import { useEffect, useState } from 'react';

import { useGoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import { toastEmmit } from '../components/LisaNotification';
import { checkToken, fetchLinkedinData, fetchProfileData, login } from '../services/AuthServices';
import { useDispatch } from 'react-redux';
// import { updateUser } from '../features/login/userSlice';
import { isAuth } from '../components/auth/auth';
import { LoaderResponse } from '../components/Loader';

import { KEY_CODE, USER_INFO_KEY } from '../constants/StoreKey';

import "../styles/Sso.css"
import EmailService from '../services/EmailServices';
import { COLORS } from '../constants/ColorsData';

const home_route = "/code"

const Sso = (props) => {
  const picto_google = "/search.png"
  const aurelia_logo = "/images/aurelia_logo.svg"

  const [isShow, setIsShow] = useState(false);
  const [runLoader, setRunLoader] = useState(true);
  const [runAnimation, setRunAnimation] = useState(false)

  let linkedinCall = 0;

  // const dispatch = useDispatch();

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      localStorage.setItem('googleToken', JSON.stringify(tokenResponse.access_token));
      setRunAnimation(true)
      
      await fetchProfileData(
        'https://www.googleapis.com/oauth2/v2/userinfo',
        tokenResponse.access_token,
        tokenResponse.token_type)
        .then(async (profile) => {
            const options = {
              firstName: profile.given_name,
              lastName: profile.family_name,
              isEnabled: true,
              email: profile.email,
              picture: profile.picture
            }
            
            await login(options).then((data) => {
              const userID = data.body.user._id.$oid
              
              localStorage.setItem('userRole', data.body.user.roles);
              localStorage.setItem(USER_INFO_KEY, JSON.stringify(data.body.user));
              localStorage.setItem('token', data.body.token);

              // dispatch(updateUser(data.body));
              const key_code = localStorage.getItem(KEY_CODE)

              if(key_code) {
                localStorage.setItem('userID', userID);
                window.location.replace("/")
              }else{
                EmailService.send(data.body.user.email, data.body.user).then((rep) => {
                  setRunAnimation(false)
                  window.location.replace(home_route);
                }).catch((e) => console.error(e))
              }
            }).catch(() => {
                console.error("Login error", "Une erreur s'est produite");
                toastEmmit("Login error", "Une erreur s'est produite");
            });
          }).catch(() => {
          toastEmmit("Profile error", "Une erreur s'est produite");
          console.error("Profile error", "Une erreur s'est produite");
        });

      localStorage.setItem('googleToken', tokenResponse);
      toastEmmit("success", "Connexion avec succès");
    },
    onError: error => {
      toastEmmit("error", "Une erreur s'est produite lors du connexion avec google");
    }
  });

  const fetchLinkedin = async (code) => {
    await fetchLinkedinData(code)
      .then(async (data) => {
        if(data.body.status === "success"){
          // console.log("Linkedin Data fecthing: ", data)
          const userID = data.body.user._id.$oid

          localStorage.setItem('userRole', data.body.user.roles);
          localStorage.setItem(USER_INFO_KEY, JSON.stringify(data.body.user));
          localStorage.setItem('token', data.body.token);

          // dispatch(updateUser(data.body));
          
          const key_code = localStorage.getItem(KEY_CODE)
            if(key_code) {
              localStorage.setItem('userID', userID);
              window.location.replace("/")
            }
            else {
              EmailService.send(data.body.user.email, data.body.user).then((rep) => {
                // console.log("Email send: ", rep)
                window.location.replace(home_route);
              }).catch((e) => console.error(e))
            }
        }else {
          console.log("Failed to access user login data")
        }
        // toastEmmit("success", "Connexion avec succès");
      }).catch(() => {
        console.error("error", "Une erreur s'est produite");
        // toastEmmit("error", "Une erreur s'est produite");
      });
  }

  const { linkedInLogin } = useLinkedIn({
    scope: "openid,email,profile",
    clientId: process.env.REACT_APP_LINKEDIN_ID,
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: async (code) => {
      linkedinCall += 1;
      if (linkedinCall === 1) {
        fetchLinkedin(code)
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const isConnected = async () => {

    if (!isAuth()) {
      setIsShow(true);
      return;
    };

    const token = localStorage.getItem('token');
    const userID = localStorage.getItem('userID');

    await checkToken(token)
      .then(user => {
        setIsShow(true);
        const key_code = localStorage.getItem(KEY_CODE)
        if(key_code) {
          window.location.replace("/")
        }
        else window.location.replace(home_route)
      })
      .catch(err => {
        setIsShow(true);
        console.log(err)
      });
  }

  useEffect(() => {
    let loaderTimeout;

    const handlePageLoad = () => {
      // Simulez un délai minimal de 2 secondes
      loaderTimeout = setTimeout(() => {
        setRunLoader(false);
      }, 2000); // Délai minimal
    };

    if (document.readyState === "complete") {
      handlePageLoad(); // Si la page est déjà chargée
    } else {
      window.addEventListener("load", handlePageLoad); // Écoutez l'événement "load"
    }

    return () => {
      clearTimeout(loaderTimeout); // Nettoyez le timeout
      window.removeEventListener("load", handlePageLoad); // Nettoyez l'événement
    };
  }, []);

  useEffect(() => {
    isConnected();
    // localStorage.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const btn = document.querySelector('.sso-google-login');

    if(btn) {
      if (!runAnimation) {
        btn.classList.remove('animated');
      } else {
        btn.classList.add('animated');
      }  
    }
  }, [runAnimation])

  return (
    <>
      {
      runLoader ? (
          <div style={{
            display: 'flex',
            flex: 1, justifyContent: 'center', alignItems: 'center',
            height: '100vh', flexDirection:
              'column',
            backgroundImage: `url("/images/fond/sso_main_bg.svg")`, backgroundRepeat: 'no-repeat', backgroundSize: "cover"
          }}>
            <LoaderResponse colorValue={COLORS.jauneClaire} w={130} h={130} />
            <p style={{ color: COLORS.jauneClaire }}>Chargement en cours...</p>
          </div>
      ) : (
          <div className='sso-container'>
            {/* <div className='sso-description-container'>
              <p className='sso-description-text'>Essayez gratuitement pendant 1 semaine tous les services d'Aurélia avec 10 crédits offerts </p>
            </div> */}
            <div className='sso-login-container'>
              <h2 className='sso-login-title'>Bienvenue sur Aurélia</h2>
              <div className='sso-login-title'>
                <img src={aurelia_logo} alt='' width={120} height={120} />
              </div>
              <div className='sso-login-container-body'>
                <div className='sso-google-login'>
                  <button className='sso-google-btn' onClick={() => loginWithGoogle()}>
                    <img style={{ width: '25px' }} src={picto_google} alt='Se connecter avec google' />
                    <p style={{fontWeight: 'bold'}}>{runAnimation ? "Redirection en cours..." : "Se connecter avec google"}</p>
                  </button>
                </div>
                {/* <div className='sso-linkedin-login'>
                  <img
                    onClick={linkedInLogin}
                    src={linkedin}
                    alt="Se connecter avec Linked In"
                    style={{ maxWidth: '226px', cursor: 'pointer' }}
                  />
                </div> */}

                <div>
                  <p className='sso-description-text'>Essayez gratuitement pendant 1 semaine tous les services d'Aurélia avec 10 crédits offerts </p>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

export default Sso;

// CampaignLauncher.tsx
import React, { useEffect, useState } from "react";
import { LoaderResponse } from "../Loader";
import "../../styles/CampagnCard.css"
import { COLORS } from "../../constants/ColorsData";
import { CAMPAGN_LOAD_STATE } from "../../constants/StoreKey";
import { CAMPAGN_TEXT_VALIDATION } from "../../constants/textVariable";


interface CampaignLauncherProps {
    header_title: string;
    header_title_bg_color?: string;
    header_title_text_color?: string;
    loading_text?: string;
    loader_color?: string;
    loading_text_color?: string;
    loader_state?: boolean;
    buttonText?: string;
    button_text_color?: string;
    buttonBgColor?: string;
    onValid?: () => void;
    children: JSX.Element;
}

const CampaignLauncher: React.FC<CampaignLauncherProps> = ({
    header_title,
    header_title_bg_color,
    header_title_text_color = "white",
    loading_text = "Validation en cours...",
    loader_color = COLORS.jauneClaire,
    loading_text_color = "black",
    loader_state = true,
    buttonText = "Valider & envoyer",
    buttonBgColor = COLORS.grisSombre,
    button_text_color = COLORS.jauneClaire,
    onValid,
    children
}) => {
    const [loader, setLoader] = useState(loader_state);
    const [btnBgc, setBtnBgc] = useState(buttonBgColor);
    const [btnTextColor, setBtnTextColor] = useState(button_text_color);

    const clickValidButton = () => {
        if(onValid) {
            onValid();
            setLoader(loader_state ? true : false);
        } else {
            setLoader(false);
        }
    }

    const updateBgColor = (e: string) => {
        if(e === "enter") {
            setBtnBgc(COLORS.jauneSombre);
            setBtnTextColor("Black")
        }
        if(e === "leave") {
            setBtnBgc(buttonBgColor);
            setBtnTextColor(button_text_color)
        }
    }

    useEffect(() => {
        setLoader(false);
        setBtnBgc(buttonBgColor);
    }, [buttonBgColor]);

    useEffect(() => {
        const ls_loader_state = localStorage.getItem(CAMPAGN_LOAD_STATE)
        
        if(ls_loader_state && ls_loader_state === CAMPAGN_TEXT_VALIDATION) {
            setLoader(false)
            localStorage.removeItem(CAMPAGN_LOAD_STATE)
        }
    }, [loader, loader_state])

    return (
        <div className="campaign-launcher">
            <div className="campaign-main">
                <div 
                    className="campaign-head" 
                    style={{ 
                        // background: header_title_bg_color ? header_title_bg_color : "#1e3a8a",
                        color: header_title_text_color
                    }}
                >
                    <div className="campaign-head-content">
                        <div className="rocket-icon">
                            <svg 
                                viewBox="0 0 24 24" 
                                width="35" 
                                height="35" 
                                fill="none" 
                                stroke="currentColor" 
                                strokeWidth="2"
                                strokeLinecap="round" 
                                strokeLinejoin="round"
                            >
                                <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
                                <path d="M12 15l-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
                                <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
                                <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="campaign-description">
                    <p style={{ textAlign: "center", fontSize: "x-large", fontWeight: 'bold', color: "black" }}>{header_title}</p>
                </div>
                <div className="campaign-body">
                    {children}
                </div>
                <div className="campaign-btn-container">
                    {loader ? (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <LoaderResponse colorValue={loader_color} w={25} h={25} />
                            <p style={{color: loading_text_color}}>{loading_text}</p>
                        </div>
                    ) : (
                        <button
                            type="button"
                            className="campaign-form-btn"
                            style={{ 
                                background: btnBgc, 
                                color: btnTextColor
                            }}
                            onClick={clickValidButton}
                            onMouseEnter={() => updateBgColor("enter")}
                            onMouseLeave={() => updateBgColor("leave")}
                        >
                            {buttonText}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CampaignLauncher;
import React, { FC } from "react";
import { Redirect2NewPage } from "../Helpers/Redirect2";
import SubNavMenuProps from "../interface/SubMenuInterface";

import "../styles/NavMenu.css"
import { USER_ID_KEY } from "../constants/StoreKey";

export const NavSubMenu: FC<SubNavMenuProps> = ({
    sub_url, 
    sub_icon, 
    sub_title, 
    sub_activeMenu, 
    sub_menuIsClicked, 
    sub_desc_name, 
    sub_disable = true, // Valeur par défaut à true car ça doit être désactivé de base
    sub_menuTitle,
    sub_parent_menu_name,
    sub_jsonData
}) => {
    const handleTab = (text: string) => {
        // Ne fait rien si le composant est désactivé
        if (sub_disable) return;

        if (sub_url) {
            const id = localStorage.getItem(USER_ID_KEY)
            Redirect2NewPage(sub_url, "", String(id))
        } else {
            sub_menuIsClicked({
                "parent_menu_name": sub_parent_menu_name,
                "page_name": text,
                "page_desc": sub_desc_name
            })
        }
    };

    return (
        <ul
            className={`
                aurelia-nav-menu 
                ${sub_disable ? "disable-hover" : "enable-hover"}
            `}
            style={{
                cursor: sub_disable ? 'not-allowed' : 'pointer',
                opacity: sub_disable ? 0.5 : 1
            }}
        >
            <li
                className={sub_activeMenu === sub_title ? "active" : "deactive"}
                onClick={() => !sub_disable && handleTab(sub_title)}
                style={{
                    ...(sub_disable && sub_activeMenu !== sub_title 
                        ? { background: "transparent", border: "none" } 
                        : {}),
                    pointerEvents: sub_disable ? 'none' : 'auto'
                }}
            >
                <img 
                    src={sub_icon} 
                    alt="" 
                    width={20} 
                    height={20} 
                    style={{
                        filter: sub_disable ? 'grayscale(100%)' : 'none'
                    }}
                />
                <p 
                    style={{
                        display: "flex", 
                        flex: 1, 
                        paddingLeft: "6px", 
                        fontWeight: 'normal', 
                        fontSize: 'small',
                        color: sub_disable ? 'rgb(210, 204, 203)' : 'white'
                    }}
                >
                    {sub_menuTitle ? sub_menuTitle : sub_title}
                </p>
            </li>
        </ul>
    );
}
import React, { FC, useEffect, useState } from "react";
import { LoaderResponse } from "../../components/Loader";
import { ItemsType, RecipientData } from "../../type/UserDataEvent";
import PageGenerationProps from "../../interface/PageInterface";

import "../../styles/FieldError.css"
import "../../styles/Destinataire.css"

import { RECIPIENT_KEY, DEST_SAVING_STATE, DOC_ID_KEY, JOB_CHECKBOX_KEY, DOMAINE_CHECKBOX_KEY, TECHNOLOGY_KEY, INCLUDE_LOCALISATION_KEY, EXCLUDE_LOCALISATION_KEY, V2_RECIPIENT_KEY } from "../../constants/StoreKey";
import { getDomainDataFromDBList, getJobListDataFromDB, getLocationDataFromDB, getTechListDataFromDB, updateRecipientOrSenderData } from "../../services/UserServices";
import { CheckBoxList } from "../../interface/DataI";
import { Redirect2NewPage } from "../../Helpers/Redirect2";
import SearchAutocomplete from "../../components/Field/InputSearchAutocomplete";
import { COLORS } from "../../constants/ColorsData";
import { encryptData } from "../../utils/util_function";
import { navigate2 } from "../../utils/redirection";
import { Box, Snackbar, TextField } from "@mui/material";
import WSnackbar from "../../components/Widget/WSnackbar";


const Recipient: FC<PageGenerationProps> = ({ userid, setPageNameToLoad, creditValue }) => {
    const user_id = userid
    const ITEM_DEFAULT_VALUE = { id: 0, name: "" }
    const TARGET_DATA_EXAMPLE = { title: "", status: false }

    const [domainDataFromServerStatus, setDomainDataFromServerStatus] = useState(false)
    const [jobDataFromServerStatus, setJobDataFromServerStatus] = useState(false)
    const [requestStatus, setRequestStatus] = useState(false);
    const [savingOk, setSavingOk] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loaderPage, setLoaderPage] = useState<boolean>(true);
    const [usingTech, setUsingTech] = useState({
        dynamics: false,
        excel: false,
        google_sheets: false,
        hubspot: false,
        pipedrive: false,
        salesforce: false
    });
    const [emptyField, setEmptyField] = useState({
        state1: false, state2: false, state3: false, state4: false, state5: false, state6: false
    });
    const [checkBoxUpdateState, setCheckBoxUpdateState] = useState({
        target_domain: false,
        target_job: false
    })

    const [userFormData, setUserFormData] = useState<RecipientData>({
        activity_domain: [TARGET_DATA_EXAMPLE],
        target_position: [TARGET_DATA_EXAMPLE],
        number_of_employees: 0,
        localisation: "||",
        company_nb_one: "",
        company_nb_two: "",
        technology: ""
    });

    const [locationFilterData, setLocationFilterData] = useState({
        include: [""],
        exclude: [""]
    })

    const [dataForSearch, setDataForSearch] = useState({
        activity_domain: [ITEM_DEFAULT_VALUE], // Même type
        target_job: [ITEM_DEFAULT_VALUE], // Même type
        location: [ITEM_DEFAULT_VALUE], // Même type
        tech: [ITEM_DEFAULT_VALUE]
    })

    const [dataForResult, setDataForResult] = useState({
        activity_domain: [TARGET_DATA_EXAMPLE],
        target_job: [TARGET_DATA_EXAMPLE],
        tech: [TARGET_DATA_EXAMPLE]
    })

    const goToNextPage = () => {
        // setPageNameToLoad("Emetteur")
        // Redirect2NewPage("Aurélia", `Dashboard/Emetteur`)
        return navigate2("Emetteur", user_id)
    }

    const extractAllTargetActivityDomainOrJob = (domain_data: any) => {
        if (domain_data) {
            let finalJsonArray = []
            const jsonArray_domain_data = JSON.parse(domain_data)

            for (const iterator of jsonArray_domain_data) {
                if (iterator['status']) {
                    finalJsonArray.push(iterator['title'])
                }
            }
            return finalJsonArray
        } else {
            return []
        }
    }

    // const updateDomaineActvity = (val: React.ChangeEvent<HTMLInputElement>) => {
    //     if(val !== undefined){
    //         setSavingOk(false)
    //         setUserFormData(prevState => ({ ...prevState, activity_domain: val.target.value }));
    //         if(val.target.value === ""){
    //             setEmptyField(
    //                 prevState => ({...prevState, state1: true})
    //             )
    //         } else setEmptyField(prevState => ({...prevState, state1: false}))
    //     }
    // }

    // eslint-disable-next-line
    const verifyDomainActvityCheckboxChecked = (checkbox_state: boolean) => {
        setSavingOk(false)
        setCheckBoxUpdateState(prevs => ({ ...prevs, target_domain: checkbox_state }))

        if (checkbox_state) setEmptyField(prevS => ({ ...prevS, state1: false })) // une casé coché, donc champ non vide
        else setEmptyField(prevS => ({ ...prevS, state1: true })) // sinon

        const ls_recipient_data = localStorage.getItem(DOMAINE_CHECKBOX_KEY)
        if (ls_recipient_data) setUserFormData(prevs => ({ ...prevs, activity_domain: JSON.parse(ls_recipient_data) }))
    }

    // eslint-disable-next-line
    const verifyJobTargetCheckboxChecked = (checkbox_state: boolean) => {
        setSavingOk(false)
        setCheckBoxUpdateState(prevs => ({ ...prevs, target_job: checkbox_state }))

        if (checkbox_state) setEmptyField(prevS => ({ ...prevS, state2: false })) // une casé coché, donc champ non vide
        else setEmptyField(prevS => ({ ...prevS, state2: true })) // sinon

        const ls_sender_data = localStorage.getItem(JOB_CHECKBOX_KEY)
        if (ls_sender_data) setUserFormData(prevs => ({ ...prevs, target_position: JSON.parse(ls_sender_data) }))
    }

    // const updatePosteCible = (val: React.ChangeEvent<HTMLInputElement>) => {
    //     if(val !== undefined){
    //         setSavingOk(false)
    //         setUserFormData(prevState => ({ ...prevState, target_position: val.target.value }));
    //         if(val.target.value === ""){
    //             setEmptyField(
    //                 prevState => ({...prevState, state2: emptyField.state2})
    //             )
    //         } else setEmptyField(prevState => ({...prevState, state2: false}))
    //     }
    // }

    const updateNbEmployees = (val: React.ChangeEvent<HTMLInputElement>) => {
        if (val !== undefined) {
            if (Number(val.target.value)) {
                setSavingOk(false)
                setUserFormData(prevState => ({
                    ...prevState,
                    number_of_employees: Number(val.target.value)
                }));
            }

            if (val.target.value === "") {
                setEmptyField(
                    prevState => ({ ...prevState, state3: emptyField.state3 })
                )
            } else setEmptyField(prevState => ({ ...prevState, state3: false }))
        }
    }

    // eslint-disable-next-line
    const updateLocalisation = (val: React.ChangeEvent<HTMLInputElement>) => {
        if (val !== undefined) {
            setSavingOk(false)
            setUserFormData(prevState => ({ ...prevState, localisation: val.target.value }));
            if (val.target.value === "") {
                setEmptyField(
                    prevState => ({ ...prevState, state4: emptyField.state4 })
                )
            } else setEmptyField(prevState => ({ ...prevState, state4: false }))
        }
    }

    const checkAndUpdateFieldEmptyOrNot = (store_key: string, value_state: boolean) => {
        switch (store_key) {
            case DOMAINE_CHECKBOX_KEY:
                setCheckBoxUpdateState(prevs => ({ ...prevs, target_domain: value_state }))
                break
            case JOB_CHECKBOX_KEY:
                setCheckBoxUpdateState(prevs => ({ ...prevs, target_job: value_state }))
                break
            default:
                break
        }
    }

    // L'utilisateur a choisi parmi le résultat de la recherche (checked)
    const updateCheckBoxStatus = (prevData: Array<CheckBoxList>, domain: { id: number, name: string }, storage_name: string = "store_box", target_type: string = "") => {
        const newData = prevData.map((item, index) => {
            if (item.title === domain.name) {
                return {
                    ...item,
                    status: !item.status
                };
            }
            return item;
        });

        localStorage.setItem(storage_name, JSON.stringify(newData));

        switch (target_type) {
            case "Domain":
                setUserFormData(prevState => ({ ...prevState, activity_domain: newData }));
                // Mettre à jour la data de recherche par autocomplétions
                setDataForResult(prevState => {
                    const filteredActivityDomain = prevState.activity_domain.filter(item => item.title !== domain.name);
                    // Vérifie et met  à jour le status qui affiche le champ du résultat
                    setCheckBoxUpdateState(prev => ({
                        ...prev, target_domain: filteredActivityDomain.length > 0
                    }))

                    return {
                        ...prevState,
                        activity_domain: filteredActivityDomain
                    };
                });
                break

            case "Job":
                setUserFormData(prevState => ({ ...prevState, target_position: newData }));
                // Mettre à jour la data de recherche par autocomplétions
                setDataForResult(prevState => {
                    const filteredTargetJob = prevState.target_job.filter(item => item.title !== domain.name);
                    // Vérifie et met  à jour le status qui affiche le champ du résultat
                    setCheckBoxUpdateState(prev => ({
                        ...prev, target_job: filteredTargetJob.length > 0
                    }))

                    return {
                        ...prevState,
                        target_job: filteredTargetJob
                    };
                });
                break

            default:
                break
        }
    }

    // L'utilisateur a retirer son choix sur le checkbox (unchecked)
    const updateUncheckedData = (Data: Array<CheckBoxList>, NameData: string, title: string, id: number, storage_name: string) => {
        updateCheckBoxStatus(Data, { name: title, id: id }, storage_name, NameData)
    }

    /**
     * Mettre à jour le tableau contenant le résultat de la recherche domaine d'activité
     * @param domain 
     */
    const updateDomainDataFromSelect = (domain: { id: number, name: string }) => {
        const res = dataForResult.activity_domain.some(act_domain => act_domain.title === domain.name);

        // L'élément sélectionné n'est pas dans la liste affichée
        if (!res) {
            setDataForResult(prevState => {
                const updatedIncludeSet = new Set([...prevState.activity_domain, { title: domain.name, status: true, id: domain.id }]);
                const updatedIncludeArray = Array.from(updatedIncludeSet);
                return {
                    ...prevState,
                    activity_domain: updatedIncludeArray
                };
            });

            updateCheckBoxStatus(userFormData.activity_domain, domain, DOMAINE_CHECKBOX_KEY, "Domain")
            checkAndUpdateFieldEmptyOrNot(DOMAINE_CHECKBOX_KEY, true)
        }
    }

    /**
     * Mettre à jour le tableau contenant le résultat de la recherche de poste
     * @param job 
     */
    const updateJobDataFromSelect = (job: { id: number, name: string }) => {
        const res = dataForResult.target_job.some(targ_job => targ_job.title === job.name);

        if (!res) {
            setDataForResult(prevState => {
                const updatedIncludeSet = new Set([...prevState.target_job, { title: job.name, status: true, id: job.id }]);
                const updatedIncludeArray = Array.from(updatedIncludeSet);
                return {
                    ...prevState,
                    target_job: updatedIncludeArray
                };
            });

            updateCheckBoxStatus(userFormData.target_position, job, JOB_CHECKBOX_KEY, "Job")
            checkAndUpdateFieldEmptyOrNot(JOB_CHECKBOX_KEY, true)
        }
    }

    /**
     * Mettre à jour la vue pour afficher le résultat en checkbox
     * @returns 
     */
    const updateDomainView = () => {
        let div_array: Array<JSX.Element> = []
        const conv_data = convertCheckBoxListData2ItemType(dataForResult.activity_domain)
        conv_data.forEach((item, index) => {
            if (item.name !== "") {
                div_array.push(
                    <div key={index} className="dest-result-checkbox-container">
                        <input type="checkbox" className="checkbox-case" checked={item.status} onChange={() => { updateUncheckedData(userFormData.activity_domain, "Domain", item.name, item.id, DOMAINE_CHECKBOX_KEY) }} />
                        <label style={{ width: "55vh" }} className="label-checkbox">{item.name}</label>
                    </div>
                )
            }
        })
        return <>
            {div_array}
        </>
    }

    const updateJobView = () => {
        let div_array: Array<JSX.Element> = []
        const conv_data = convertCheckBoxListData2ItemType(dataForResult.target_job)
        conv_data.forEach((item, index) => {
            if (item.name !== "") {
                div_array.push(
                    <div key={index} className="dest-result-checkbox-container">
                        <input type="checkbox" className="checkbox-case" checked={item.status} onChange={() => { updateUncheckedData(userFormData.target_position, "Job", item.name, item.id, JOB_CHECKBOX_KEY) }} />
                        <label style={{ width: "55vh" }} className="label-checkbox">{item.name}</label>
                    </div>
                )
            }
        })
        return <>
            {div_array}
        </>
    }

    const updateTechnologyView = () => {
        let div_array: Array<JSX.Element> = []

        if (dataForResult.tech.length > 0) {
            const conv_data = convertCheckBoxListData2ItemType(dataForResult.tech)
            conv_data.forEach((item, index) => {
                if (item.name !== "") {
                    div_array.push(
                        <div key={index} className="dest-result-checkbox-container">
                            <input type="checkbox" className="checkbox-case" checked={item.status} onChange={() => { updateTechData4UncheckedChecbox(item.name) }} />
                            <label className="label-checkbox">{item.name}</label>
                        </div>
                    )
                }
            })
            return <>
                {div_array}
            </>
        }
    }

    const formatAndUpdateLocationData = () => {
        var location_final_data = ""
        if (locationFilterData.include.length > 0) {
            location_final_data = concatenerMots(locationFilterData.include)
            // Sauvegarde en storage
            localStorage.setItem(INCLUDE_LOCALISATION_KEY, JSON.stringify({
                fullText: locationFilterData.include,
                compactText: location_final_data
            }))
        }

        if (locationFilterData.exclude.length > 0) {
            location_final_data += "||"
            const new_exclude_localisation = fusionnerMots(locationFilterData.exclude)
            // Sauvegarde en storage
            localStorage.setItem(EXCLUDE_LOCALISATION_KEY, JSON.stringify({
                fullText: locationFilterData.exclude,
                compactText: new_exclude_localisation
            }))
            // Fusion 
            location_final_data += new_exclude_localisation
        }

        if (location_final_data !== "") {
            setUserFormData(prevState => ({
                ...prevState,
                localisation: location_final_data
            }));
        }
        return location_final_data
    }

    // eslint-disable-next-line
    const removeDuplicateText = (words: string[]): string => {
        // Utilisez un ensemble pour stocker des mots uniques
        const uniqueWords = new Set<string>();

        // Parcours du tableau et ajout des mots uniques à l'ensemble
        words.forEach(word => {
            // Divise le mot s'il contient des espaces et ajoute chaque partie à l'ensemble
            word.split(',').forEach(part => uniqueWords.add(part.trim()));
        });

        // Convertit l'ensemble en tableau et trie les mots
        const uniqueWordsArray = Array.from(uniqueWords).sort();

        // Rejoint les mots avec une virgule et les retourne comme une chaîne
        return uniqueWordsArray.join(', ');
    }

    const updateSelectIncludeLocation = (updt_location: { id: number, name: string }) => {
        const res = locationFilterData.include.includes(updt_location.name)

        if (!res) {
            setLocationFilterData(prevState => {
                const updatedIncludeSet = new Set([...prevState.include, updt_location.name]);
                const updatedIncludeArray = Array.from(updatedIncludeSet);
                return {
                    ...prevState,
                    include: updatedIncludeArray
                };
            });
        }
    }

    // eslint-disable-next-line
    const updateSelectExcludeLocation = (updt_location: { id: number, name: string }) => {
        const res = locationFilterData.exclude.includes(updt_location.name)

        if (!res) {
            setLocationFilterData(prevState => {
                const updatedExcludeSet = new Set([...prevState.exclude, updt_location.name]);
                const updatedExcludeArray = Array.from(updatedExcludeSet);
                return {
                    ...prevState,
                    exclude: updatedExcludeArray
                };
            });

        }
    }

    const removeLocationResult = (location_type: "inclure" | "exclure", input_value: string) => {
        switch (location_type) {
            case "inclure":
                setLocationFilterData(prevState => {
                    const updatedIncludeArray = prevState.include.filter(value => value !== input_value);
                    return {
                        ...prevState,
                        include: updatedIncludeArray
                    };
                });

                break
            case "exclure":
                setLocationFilterData(prevState => {
                    const updatedExcludeArray = prevState.exclude.filter(value => value !== input_value);
                    return {
                        ...prevState,
                        exclude: updatedExcludeArray
                    };
                });

                break
            default:
                break;
        }
    }

    const locationIncludeView = () => {
        let div_array: Array<JSX.Element> = []
        if (locationFilterData.include[0] === "") locationFilterData.include = []

        locationFilterData.include.forEach((item, index) => {
            div_array.push(
                <div key={index} style={{ display: "flex", flex: 1 }}>
                    <div key={index} className="dest-result-checkbox-container">
                        <input type="checkbox" className="checkbox-case" checked={true} onChange={() => { removeLocationResult("inclure", item) }} />
                        <label className="label-checkbox">{item}</label>
                    </div>
                </div>
            )
        })
        return <>
            {div_array}
        </>
    }

    const locationExludeView = () => {
        let div_array: Array<JSX.Element> = []
        if (locationFilterData.exclude[0] === "") locationFilterData.exclude = []

        locationFilterData.exclude.forEach((item, index) => {
            div_array.push(
                <div key={index} style={{ display: "flex", flex: 1 }}>
                    <div key={index} className="dest-result-checkbox-container">
                        <input type="checkbox" className="checkbox-case" checked={true} onChange={() => { removeLocationResult("exclure", item) }} />
                        <label className="label-checkbox">{item}</label>
                    </div>
                </div>
            )
        })
        return <>
            {div_array}
        </>
    }

    /**
     * 
     * @param updt_tech 
     */
    const updateSelectTechnology = (updt_tech: { id: number, name: string }) => {
        const techno = Array({ title: updt_tech.name, status: true })
        setDataForResult(prev => ({ ...prev, tech: techno }))
        setUsingTech(prev => ({ ...prev, dynamics: true }))
        localStorage.setItem(TECHNOLOGY_KEY, updt_tech.name)
    }

    /**
     * Supprime de la variable résultat de recherche le nom choisi (uncheck checkbox)
     * @param title
     * @param storage_name 
     */
    const updateTechData4UncheckedChecbox = (title: string, storage_name: string = TECHNOLOGY_KEY) => {
        var techno = dataForResult.tech

        for (var tech in techno) {
            if (techno[tech].title === title) {
                delete techno[tech];
                techno = []
            }
        }

        setDataForResult(prev => ({ ...prev, tech: techno }))
        setUsingTech(prevStatus => ({ ...prevStatus, dynamics: false }))

        localStorage.removeItem(storage_name)
    }

    const updateCompany1 = (val: React.ChangeEvent<HTMLInputElement>) => {
        if (val !== undefined) {
            setSavingOk(false)
            setUserFormData(prevState => ({ ...prevState, company_nb_one: val.target.value }));
            if (val.target.value === "") {
                setEmptyField(
                    prevState => ({ ...prevState, state5: emptyField.state5 })
                )
            } else setEmptyField(prevState => ({ ...prevState, state5: false }))
        }
    }

    const updateCompany2 = (val: React.ChangeEvent<HTMLInputElement>) => {
        if (val !== undefined) {
            setSavingOk(false)
            setUserFormData(prevState => ({ ...prevState, company_nb_two: val.target.value }));
            if (val.target.value === "") {
                setEmptyField(
                    prevState => ({ ...prevState, state6: emptyField.state6 })
                )
            } else setEmptyField(prevState => ({ ...prevState, state6: false }))
        }
    }

    // Fonction 1: Concaténation du premier mot avec les seconds mots de chaque élément
    function concatenerMots(liste: string[]): string {
        const premiersMots: Set<string> = new Set();
        const resultats: string[] = [];
        for (const element of liste) {
            const mots = element.split(", ");
            if (mots.length === 1) { // Si l'élément contient un seul mot
                if (!premiersMots.has(mots[0])) {
                    resultats.push(mots[0]); // Utiliser ce mot comme premier mot si ce n'est pas un doublon
                    premiersMots.add(mots[0]);
                }
            } else {
                if (!premiersMots.has(mots[0])) {
                    resultats.push(mots[0]); // Ajouter le premier mot si ce n'est pas un doublon
                    premiersMots.add(mots[0]);
                }
                resultats.push(...mots.slice(1)); // Ajouter les seconds mots
            }
        }
        return resultats.join(", "); // Concaténer avec ", " entre chaque mot
    }

    // Fonction 2: Fusion de tous les mots après le virgule de chaque élément
    function fusionnerMots(liste: string[]): string {
        const motsApresVirgule: string[] = [];
        for (const element of liste) {
            const mots = element.split(", ");
            motsApresVirgule.push(...mots.slice(1)); // Ajoute les seconds mots
        }
        return motsApresVirgule.join(", "); // Concatène tous les mots après le virgule
    }

    const saveUserDataToLocal = () => {
        // Aucun checkbox chois pour le domaine d'activité cible - Données non mis à jour - on n'utilise pas une technologie
        if (!usingTech.dynamics && (!checkBoxUpdateState.target_domain || !userFormData.activity_domain)) {
            // Déclencher l'erreur champ obligatoire
            setEmptyField(
                prevState => ({ ...prevState, state1: true })
            )
            return
        }

        // Aucun checkbox chois pour le poste cible - Données non mis à jour
        if (!checkBoxUpdateState.target_job || !userFormData.target_position) {
            // Déclencher l'erreur champ obligatoire
            setEmptyField(
                prevState => ({ ...prevState, state2: true })
            )
            return;
        }

        if (!userFormData.number_of_employees || userFormData.number_of_employees < 10) {
            setEmptyField(
                prevState => ({ ...prevState, state3: true })
            )
            return
        } else setEmptyField(prevState => ({ ...prevState, state3: false }))

        // if (!userFormData.company_nb_one){
        //     setEmptyField(
        //         prevState => ({...prevState, state5: true})
        //     )
        //     return
        // }else setEmptyField(prevState => ({...prevState, state5: false}))

        // if(!userFormData.company_nb_two) {
        //     setEmptyField(
        //         prevState => ({...prevState, state6: true})
        //     )
        //     return;
        // } else setEmptyField(prevState => ({...prevState, state6: false}))

        /* Localisation */
        // if(!userFormData.localisation) {
        //     setEmptyField(
        //         prevState => ({...prevState, state4: true})
        //     )
        //     return;
        // } else setEmptyField(prevState => ({...prevState, state4: false}))
        var localisation_temp_data = formatAndUpdateLocationData()

        const domain_data = localStorage.getItem(DOMAINE_CHECKBOX_KEY)
        const job_data = localStorage.getItem(JOB_CHECKBOX_KEY)
        const techno = localStorage.getItem(TECHNOLOGY_KEY)

        if (user_id) {
            setRequestStatus(true)
            setUserFormData(prev => ({ ...prev, user_id: user_id }))

            const doc_id = localStorage.getItem(DOC_ID_KEY)
            const data_recipient_to_save = {
                "activity_domain": JSON.parse(String(domain_data)),
                "target_position": JSON.parse(String(job_data)),
                "localisation": localisation_temp_data,
                "number_of_employees": userFormData.number_of_employees ? userFormData.number_of_employees : 500,
                "company_nb_one": userFormData.company_nb_one ? userFormData.company_nb_one : "",
                "company_nb_two": userFormData.company_nb_two ? userFormData.company_nb_two : "",
                "technology": techno ? techno : ""
            }

            const v2_data_recipient_to_save = {
                "targetActivityDomain": extractAllTargetActivityDomainOrJob(domain_data),
                "targetJob": extractAllTargetActivityDomainOrJob(job_data),
                "location": {
                    "include": locationFilterData.include,
                    "exclude": locationFilterData.exclude
                },
                "min_employes_number": userFormData.number_of_employees ? userFormData.number_of_employees : 500,
                "company_one": userFormData.company_nb_one ? userFormData.company_nb_one : "",
                "company_two": userFormData.company_nb_two ? userFormData.company_nb_two : "",
                "technology": techno ? [techno] : [],
            }

            encryptData(V2_RECIPIENT_KEY, v2_data_recipient_to_save)
            localStorage.setItem(RECIPIENT_KEY, JSON.stringify(data_recipient_to_save))
            localStorage.setItem(DEST_SAVING_STATE, String(true))

            if (localStorage.getItem(RECIPIENT_KEY)) {
                updateRecipientOrSenderData({
                    "_id": doc_id,
                    "user_id": user_id,
                    "cle": "recipient",
                    "body": v2_data_recipient_to_save
                }).then((rep: any) => {
                    if (doc_id === null || doc_id === undefined) localStorage.setItem(DOC_ID_KEY, rep.body._id)

                    setRequestStatus(false)
                    setOpenSnackbar(true)
                    setSavingOk(true)
                    // goToNextPage()
                }).catch((e) => {
                    setRequestStatus(false)
                    console.error(e)
                })
            }
        }
    }

    const handleClick = () => {

    };

    const convertCheckBoxListData2ItemType = (checkBoxData: Array<CheckBoxList>) => {
        let itemData: Array<ItemsType> = []

        checkBoxData.forEach((item, index) => {
            itemData.push({
                id: index,
                name: item.title,
                status: item.status
            })
        })

        return itemData
    }

    const getCheckedDataFromSearchResult = (checkBoxData: Array<CheckBoxList>) => {
        let itemData: Array<{ title: string, status: boolean }> = []

        checkBoxData.forEach((item, index) => {
            if (item.status) {
                itemData.push({
                    // id: index,
                    title: item.title,
                    status: item.status
                })
            }
        })

        return itemData
    }


    useEffect(() => {
        // Récupérer la liste de tous les domaines d'activités
        getDomainDataFromDBList().then((rep: any) => {
            const body: Array<CheckBoxList> = rep.body[0]['domain_title_and_state']

            // Préparation :
            var temp_domain = localStorage.getItem(DOMAINE_CHECKBOX_KEY)
            if (temp_domain) {
                const parsed_data: Array<CheckBoxList> = JSON.parse(temp_domain)
                if (body.length > parsed_data.length) {
                    localStorage.removeItem(DOMAINE_CHECKBOX_KEY)
                    localStorage.setItem(DOMAINE_CHECKBOX_KEY, JSON.stringify(body))
                }
            } else localStorage.setItem(DOMAINE_CHECKBOX_KEY, JSON.stringify(body))

            if (body.length > 0) {
                if (body.length % 2 === 1) {
                    body.push({
                        title: "",
                        status: false
                    })
                }

                const ls_target_domain = localStorage.getItem(DOMAINE_CHECKBOX_KEY)
                if (ls_target_domain) {
                    const parsed_data: Array<CheckBoxList> = JSON.parse(ls_target_domain)

                    setUserFormData(prevUserFormData => ({
                        ...prevUserFormData,
                        activity_domain: parsed_data.map(item => ({ title: item.title, status: item.status }))
                    }));
                } else {
                    localStorage.setItem(DOMAINE_CHECKBOX_KEY, JSON.stringify(body))
                    setUserFormData(prevUserFormData => ({
                        ...prevUserFormData,
                        activity_domain: body.map(item => ({ title: item.title, status: item.status }))
                    }));
                }
                setDomainDataFromServerStatus(true)
            }
        }).catch((e) => {
            setDomainDataFromServerStatus(true)
            console.error("Get domain list error: ", e)
        })

        // Récupérer la liste de tous les postes
        getJobListDataFromDB().then((rep: any) => {
            const liste: Array<CheckBoxList> = rep.body['title_and_state']

            // Préparation :
            var temp_domain = localStorage.getItem(JOB_CHECKBOX_KEY)
            if (temp_domain) {
                const parsed_data: Array<CheckBoxList> = JSON.parse(temp_domain)

                if (parsed_data.length !== liste.length) {
                    localStorage.setItem(JOB_CHECKBOX_KEY, JSON.stringify(liste))
                }
            } else localStorage.setItem(JOB_CHECKBOX_KEY, JSON.stringify(liste))

            if (liste.length > 0) {
                if (liste.length % 2 === 1) {
                    liste.push({
                        title: "",
                        status: false
                    })
                }

                const ls_target_job = localStorage.getItem(JOB_CHECKBOX_KEY)

                if (ls_target_job) {
                    const parsed_data: Array<CheckBoxList> = JSON.parse(ls_target_job)

                    setUserFormData(prevUserFormData => ({
                        ...prevUserFormData,
                        target_position: parsed_data.map(item => ({ title: item.title, status: item.status }))
                    }));
                } else {
                    setUserFormData(prevUserFormData => ({
                        ...prevUserFormData,
                        target_position: liste.map(item => ({ title: item.title, status: item.status }))
                    }));
                }
                setJobDataFromServerStatus(true)
            }
        }).catch((e) => {
            setJobDataFromServerStatus(true)

            console.error("Get job list error: ", e)
        })

        // Location : France, Lile
        getLocationDataFromDB().then((rep) => {
            if (rep && rep.body) setDataForSearch(prev => ({ ...prev, location: rep.body }))
        }).catch((e) => console.error("Get Location data error: ", e))

        // Récupérer la liste des technologies
        getTechListDataFromDB().then((rep: any) => {
            if (rep && rep.body) {
                var tech: Array<{ id: number, name: string }> = []
                rep.body.forEach((items: any, index: number) => {
                    tech.push({
                        id: index,
                        name: items.name
                    })
                })

                setDataForSearch(prev => ({ ...prev, tech: tech }))
            }
        })

    }, [])

    useEffect(() => {
        let data_retrieve = localStorage.getItem(RECIPIENT_KEY)
        if (data_retrieve && Object.keys(JSON.parse(data_retrieve)).length > 0) {
            let myJson = JSON.parse(data_retrieve)

            setUserFormData(myJson)
            setSavingOk(Boolean(localStorage.getItem(DEST_SAVING_STATE)))
            setUserFormData(prevState => ({ ...prevState, number_of_employees: Number(myJson['min_employes_number']) }))
            
        }
    }, [])

    useEffect(() => {
        if (userFormData.activity_domain && userFormData.target_position) {
            const domain_converted_data = convertCheckBoxListData2ItemType(userFormData.activity_domain)
            const job_converted_data = convertCheckBoxListData2ItemType(userFormData.target_position)

            setDataForSearch(prev => ({
                ...prev, activity_domain: domain_converted_data, target_job: job_converted_data
            }))
        }

        const ls_act_domain = localStorage.getItem(DOMAINE_CHECKBOX_KEY)
        const ls_job = localStorage.getItem(JOB_CHECKBOX_KEY)
        const ls_tech = localStorage.getItem(TECHNOLOGY_KEY)
        const ls_inc_loc = localStorage.getItem(INCLUDE_LOCALISATION_KEY)
        const ls_exc_loc = localStorage.getItem(EXCLUDE_LOCALISATION_KEY)

        // Chargement du domaine d'activité cible
        if (ls_act_domain) {
            const act_domain_res = getCheckedDataFromSearchResult(JSON.parse(ls_act_domain))

            setDataForResult(prev => ({ ...prev, activity_domain: act_domain_res }))
            setCheckBoxUpdateState(prev => ({ ...prev, target_domain: act_domain_res.length > 0 }))
        }

        // Chargement du poste cible
        if (ls_job) {
            const job_res = getCheckedDataFromSearchResult(JSON.parse(ls_job))

            setDataForResult(prev => ({ ...prev, target_job: job_res }))
            setCheckBoxUpdateState(prev => ({ ...prev, target_job: job_res.length > 0 }))
        }

        // Chargement du technologie utilisée
        if (ls_tech) {
            const tech_data = Array({
                title: ls_tech,
                status: ls_tech !== "" || ls_tech !== null || ls_tech !== undefined ? true : false
            })

            setDataForResult(prev => ({ ...prev, tech: tech_data }))
            setCheckBoxUpdateState(prev => ({ ...prev, target_job: tech_data.length > 0 }))
            setUsingTech(prev => ({ ...prev, dynamics: true }))
        }

        // Chargement des localisations
        if (ls_inc_loc) {
            const inc_loc = JSON.parse(ls_inc_loc)
            setLocationFilterData(prev => ({ ...prev, include: inc_loc.fullText }))
        }
        if (ls_exc_loc) {
            const exc_loc = JSON.parse(ls_exc_loc)
            setLocationFilterData(prev => ({ ...prev, exclude: exc_loc.fullText }))
        }

    }, [userFormData.activity_domain, userFormData.target_position])

    useEffect(() => {
        setTimeout(() => {
            setLoaderPage(false)
        }, 2000); // Délai minimal
    }, [])
    

    return <>
        {
            loaderPage ? (
                <div className="dest-form-input-left" style={{ justifyContent: "center", alignItems: "center" }}>
                    <LoaderResponse colorValue={COLORS.jauneClaire} w={130} h={130} />
                    <p style={{ color: COLORS.jauneClaire }}>Chargement en cours...</p>
                </div>
            ) : (
                <>
                    <div className="scroll" onClick={handleClick} style={{ display: "flex", flex: 1, flexDirection: 'column' }}>
                        <div className="dest-form-input">
                            <div className="dest-search-container">
                                <div className="dest-search-left-container">
                                    {
                                        domainDataFromServerStatus ? (
                                            <div className="dest-form-input-left">
                                                <div className="dest-row">
                                                    <label>Domaine d'activité cible {usingTech.dynamics ? "(Optionnel)" : ""}</label>
                                                    <div className={emptyField.state1 ? "dest-search-result-container container-error" : "dest-search-result-container container-no-error"}>
                                                        <SearchAutocomplete
                                                            items={dataForSearch.activity_domain}
                                                            itemSelected={updateDomainDataFromSelect}
                                                            Placeholder="Tapez ici le domaine d'activité"
                                                        />
                                                        {/* {
                                                    checkBoxUpdateState.target_domain && (
                                                        <div  className="dest-location-search-result dest-location-scroll">
                                                            {updateDomainView()}
                                                        </div>
                                                    )
                                                } */}
                                                    </div>
                                                    {
                                                        checkBoxUpdateState.target_domain && (
                                                            <div className="dest-location-search-result dest-location-scroll">
                                                                {updateDomainView()}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="dest-form-input-left" style={{ justifyContent: "center", alignItems: "center" }}>
                                                <LoaderResponse colorValue={COLORS.jauneClaire} />
                                            </div>
                                        )
                                    }

                                    {
                                        jobDataFromServerStatus ? (
                                            <div className="dest-form-input-left">
                                                <div className="dest-row">
                                                    <label>Poste de la cible visé</label>
                                                    <div className={emptyField.state2 ? "dest-search-result-container container-error" : "dest-search-result-container container-no-error"}>
                                                        <SearchAutocomplete
                                                            items={dataForSearch.target_job}
                                                            itemSelected={updateJobDataFromSelect}
                                                            Placeholder="Tapez ici le nom de la poste"
                                                        />
                                                        {/* {
                                                    checkBoxUpdateState.target_job && (
                                                        <div  className="dest-location-search-result dest-location-scroll">
                                                            {updateJobView()}
                                                        </div>
                                                    )
                                                } */}
                                                    </div>
                                                    {
                                                        checkBoxUpdateState.target_job && (
                                                            <div className="dest-location-search-result dest-location-scroll">
                                                                {updateJobView()}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="dest-form-input-left" style={{ justifyContent: "center", alignItems: "center" }}>
                                                <LoaderResponse colorValue={COLORS.jauneClaire} />
                                            </div>
                                        )
                                    }
                                </div>

                                <div className="dest-optionnel">
                                    <div className="dest-optionnal-left-container">
                                        <div className="dest-form-input-left">
                                            <div className="dest-row">
                                                <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                                    <TextField
                                                        id="outlined-number"
                                                        label="Nombre total des employés"
                                                        type="number"
                                                        className={emptyField.state3 ? "error-field input_text" : "no-error input_text"}
                                                        defaultValue={userFormData.number_of_employees}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            updateNbEmployees(event)
                                                        }}
                                                        style={{ border: emptyField.state3 ? "solid 1px red" : ""}}
                                                    />
                                                </Box>
                                            </div>
                                        </div>

                                        <div className="dest-search-right-container">
                                            <div className="dest-row">
                                                <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                                    <TextField id="outlined-basic" label="Nom de la 1ère entreprise (Optionnel)" variant="outlined" fullWidth multiline rows={1}
                                                        defaultValue={userFormData.company_nb_one}
                                                        className={emptyField.state5 ? "error-field input_text" : "no-error input_text"}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            updateCompany1(event)
                                                        }}
                                                        style={{ color: COLORS.grisSombre, border: emptyField.state5 ? "solid 1px red" : ""}}
                                                    />
                                                </Box>
                                            </div>
                                            <div className="dest-row">
                                                <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                                    <TextField id="outlined-basic" label="Nom du 2ème entreprise (Optionnel)" variant="outlined" fullWidth multiline rows={1}
                                                        style={{ color: COLORS.grisSombre, border: emptyField.state6 ? "solid 1px red" : "" }}
                                                        defaultValue={userFormData.company_nb_two}
                                                        className={emptyField.state6 ? "error-field input_text" : "no-error input_text"}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            updateCompany2(event)
                                                        }}
                                                    />
                                                </Box>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dest-optionnal-right-container">
                                        <div className="dest-form-label-location"><label>Localisation (Optionnel)</label></div>
                                        <div className="dest-form-input-location-main-container">
                                            <div className="dest-form-input-location-container">
                                                {
                                                    jobDataFromServerStatus && dataForSearch.location.length > 1 ? (
                                                        <div className="dest-form-input-include">
                                                            <div className="dest-row">
                                                                <label>1. Inclure</label>
                                                                <div className={"dest-search-result-container container-no-error"}>
                                                                    <SearchAutocomplete
                                                                        items={dataForSearch.location}
                                                                        itemSelected={updateSelectIncludeLocation}
                                                                        Placeholder="La ville à inclure"
                                                                        zindexValue={5}
                                                                    />
                                                                </div>
                                                                {
                                                                    locationFilterData.include.length > 0 && (
                                                                        <div className="dest-location-search-result dest-location-scroll-search-result">
                                                                            {locationIncludeView()}
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="dest-form-input-left" style={{ justifyContent: "center", alignItems: "center" }}>
                                                            <LoaderResponse colorValue={COLORS.jauneClaire} />
                                                        </div>
                                                    )
                                                }

                                                {
                                                    jobDataFromServerStatus && dataForSearch.location.length > 1 ? (
                                                        <div className="dest-form-input-include">
                                                            <div className="dest-row">
                                                                <label>2. Exclure</label>
                                                                <div className={"dest-search-result-container container-no-error"}>
                                                                    <SearchAutocomplete
                                                                        items={dataForSearch.location}
                                                                        itemSelected={updateSelectExcludeLocation}
                                                                        Placeholder="La ville à exclure"
                                                                        zindexValue={5}
                                                                    />
                                                                </div>
                                                                {
                                                                    locationFilterData.exclude.length > 0 && (
                                                                        <div className="dest-location-search-result dest-location-scroll-search-result">
                                                                            {locationExludeView()}
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="dest-form-input-left" style={{ justifyContent: "center", alignItems: "center" }}>
                                                            <LoaderResponse colorValue={COLORS.jauneClaire} />
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className="dest-form-input-location-container">
                                                <div className="dest-row" style={{ flex: 1 }}>
                                                    <label>Technologies utilisées (Optionnel)</label>
                                                    <div className={"dest-search-result-container container-no-error"}>
                                                        <SearchAutocomplete
                                                            items={dataForSearch.tech}
                                                            itemSelected={updateSelectTechnology}
                                                            Placeholder="Ex: Dynamics 365, Google Sheets..."
                                                            zindexValue={4}
                                                        />
                                                    </div>
                                                    {
                                                        dataForResult.tech.length > 0 &&
                                                        <div className="dest-location-search-result dest-location-scroll">
                                                            {updateTechnologyView()}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="dest-separator"></div> */}
                        </div>
                        <div style={{ display: "flex", flex: 1 }}>

                        </div>
                    </div>

                    <div className="dest-form-btn-container">
                        {
                            requestStatus ? (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <LoaderResponse colorValue={COLORS.jauneClaire} />
                                    <p style={{ color: 'black', paddingLeft: 10 }}>Enregistrement en cours...</p>
                                </div>
                            ) : (
                                domainDataFromServerStatus && jobDataFromServerStatus && 
                                    <button id="form-btn" onClick={saveUserDataToLocal}>
                                        {savingOk ? "Enregistré et validé" : "Enregistrer"}
                                    </button>
                            )
                        }
                        { openSnackbar && <WSnackbar OPEN={openSnackbar} MESSAGE="Informations sur le destinataire validées et enregistrées" redirectFunction={goToNextPage} />}
                    </div>
                </>
            )
        }
    </>
}

export default Recipient
import React, { useEffect, useState } from "react";

import "../../styles/Campagne.css"
import PageGenerationProps from "../../interface/PageInterface";
import { DEST_SAVING_STATE, FOLLOWUP_SAVING_STATE, FOLLOW_UP_DATA, PROMPT2LLM_KEY, SENDER_SAVING_STATE, USER_ID_KEY } from "../../constants/StoreKey";
import { decryptData, encryptData } from "../../utils/util_function";
import { COLORS } from "../../constants/ColorsData";
import { useParams } from "react-router-dom";
import { navigate2 } from "../../utils/redirection";
import { Box, TextField } from "@mui/material";
import { LoaderResponse } from "../../components/Loader";
import WSnackbar from "../../components/Widget/WSnackbar";
import { IFollowUp } from "../../interface/DataI";
import { followUp_template } from "../../constants/ArrayData";


const FollowUpPage: React.FC<PageGenerationProps> = ({ creditValue }) => {
    const params = useParams();

    const [userID, setID] = useState<string>("");
    const [currentID, setCurrentID] = useState("")
    const [requestStatus, setRequestStatus] = useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [savingOk, setSavingOk] = useState<boolean>(false);

    const [loaderPage, setLoaderPage] = React.useState(true)
    const [emptyField, setEmptyField] = useState({
        state1: false,
        state2: false
    })

    const [followup_data2send, setFollowup_data2send] = React.useState<IFollowUp>({
        subject: "",
        body: followUp_template[0],
    })

    const goToNextPage = () => {
        navigate2("CampaignConfig", currentID)
    }

    const saveUserDataToLocal = () => {
        if (!followup_data2send.subject){
            setEmptyField(
                prevState => ({...prevState, state1: true})
            )
            return
        }else setEmptyField(prevState => ({...prevState, state1: false}))

        if(!followup_data2send.body) {
            setEmptyField(
                prevState => ({...prevState, state2: true})
            )
            return;
        } else setEmptyField(prevState => ({...prevState, state2: false}))

        encryptData(FOLLOW_UP_DATA, followup_data2send)
        encryptData(FOLLOWUP_SAVING_STATE, true)

        setSavingOk(true)
        setOpenSnackbar(true)
    }

    useEffect(() => {
        const temp_id = localStorage.getItem(USER_ID_KEY)
        if(temp_id) setCurrentID(temp_id)

        if(localStorage.getItem(FOLLOWUP_SAVING_STATE)) setSavingOk(Boolean(decryptData(FOLLOWUP_SAVING_STATE)))
    }, [])

    // Vérifier si on a déjà configurer les informations sur le destinataire, l'émetteur, et le mail
    useEffect(() => {
        if (params.id) {
            if (localStorage.getItem(DEST_SAVING_STATE) === null) {
                return navigate2("Destinataire", params.id)
            } else if (localStorage.getItem(SENDER_SAVING_STATE) === null) {
                return navigate2("Emetteur", params.id)
            } else if (localStorage.getItem(PROMPT2LLM_KEY) === null) {
                return navigate2("Mail", params.id)
            }
        }

        const decoded_followup_data = decryptData(FOLLOW_UP_DATA)

        if (decoded_followup_data) {
            setFollowup_data2send(decoded_followup_data)
            setLoaderPage(false)
        } else setLoaderPage(false)
    }, [params.id])

    useEffect(() => {
        setTimeout(() => {
            setLoaderPage(false)
        }, 2000); // Délai minimal
    }, [])



    return (
        <>
            {
                loaderPage ? (
                    <div className="dest-form-input-left" style={{ justifyContent: "center", alignItems: "center" }}>
                        <LoaderResponse colorValue={COLORS.jauneClaire} w={130} h={130} />
                        <p style={{ color: COLORS.jauneClaire }}>Chargement en cours...</p>
                    </div>
                ) : (
                    <div>
                        <div id="form-input">
                            <div className="row">
                                <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    <TextField
                                        id="outlined-basic"
                                        label="Titre du mail de relance"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={1}
                                        defaultValue={followup_data2send.subject}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFollowup_data2send(
                                                prevState => ({ ...prevState, subject: event.target.value })
                                            )
                                            setEmptyField(
                                                prevState => ({...prevState, state1: false})
                                            )
                                        }}
                                        style={{ border: emptyField.state1 ? "solid 1px red" : ""}}
                                    />
                                </Box>
                            </div>

                            <div className="row">
                                <Box className="box-form" component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }} noValidate autoComplete="off" >
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Exemple de mail de relance"
                                        fullWidth
                                        multiline
                                        rows={12}
                                        defaultValue={followup_data2send.body}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFollowup_data2send(
                                                prevState => ({ ...prevState, body: event.target.value })
                                            )
                                            setEmptyField(
                                                prevState => ({...prevState, state2: false})
                                            )
                                        }}
                                        style={{ border: emptyField.state2 ? "solid 1px red" : ""}}
                                    />
                                </Box>
                            </div>

                            <div id="form-btn-container">
                                {
                                    requestStatus ? (
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <LoaderResponse colorValue={COLORS.jauneClaire} />
                                            <p style={{color: 'black'}}>Enregistrement en cours...</p>
                                        </div>
                                    ) : (
                                        <button id="form-btn" onClick={saveUserDataToLocal}>{savingOk ? "Suivant" : "Enregistrer"}</button>
                                    )
                                }
                                { openSnackbar && <WSnackbar OPEN={openSnackbar} MESSAGE="Informations sur la relance enregistrées" redirectFunction={goToNextPage} TIME_DELAY={2000} />}
                            </div>
                            
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default FollowUpPage
import React, { FC } from "react";
import { Redirect2NewPage } from "../Helpers/Redirect2";
import NavMenuProps from "../interface/MenuInterface";

import "../styles/NavMenu.css"
import { USER_ID_KEY } from "../constants/StoreKey";

export const NavMenu:FC<NavMenuProps> = ({url, icon, title, activeMenu, menuIsClicked, desc_name, menuTitle, jsonData, isDisabled = false}) => {
    const handleTab = (text: string) => {
        // Ne fait rien si le composant est désactivé
        if (isDisabled) return;

        if(url){
            const id = localStorage.getItem(USER_ID_KEY)
            Redirect2NewPage(url, "", String(id))
        } else {
            menuIsClicked({
                "page_name": text,
                "page_desc": desc_name
            })
        }
    };

    return (
        <ul className="aurelia-nav-menu">
            <li
                className={`
                    ${activeMenu === title ? "active" : "deactive"}
                    ${isDisabled ? "disabled" : ""}
                `}
                onClick={
                    isDisabled 
                        ? () => {} 
                        : () => handleTab(title)
                }
                style={{
                    ...(isDisabled && activeMenu !== title 
                        ? { background: "transparent", border: "none", opacity: 0.5, cursor: 'not-allowed'} 
                        : { opacity: 1,  cursor: 'pointer'}),
                }}
            >
                <img 
                    src={icon} 
                    alt="" 
                    width={25} 
                    height={25} 
                    style={{
                        filter: isDisabled ? 'grayscale(100%)' : 'none'
                    }}
                />
                <p style={{
                    display: "flex", 
                    flex: 1, 
                    paddingLeft: "20px", 
                    fontWeight: 'normal',
                    color: isDisabled ? 'rgb(210, 204, 203)' : 'white'
                }}>
                    {menuTitle ? menuTitle : title}
                </p>
            </li>
        </ul>
    )
}
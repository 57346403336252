import React, { useEffect, useState } from "react";

import "../../styles/Crm.css"
import { Imagebutton } from "../../components/ImageButton";
import PageGenerationProps from "../../interface/PageInterface";
import { AP_CREDS_DATA, CRM_API_KEY, GS_CREDS_DATA, HS_CREDS_DATA, SF_CREDS_DATA, USER_INFO_KEY } from "../../constants/StoreKey";
import { apolloApiVerification, hubspotApiVerification } from "../../api/crm_api";
import { CrmType } from "../../type/CrmType";
import { LoaderResponse } from "../../components/Loader";
import { decryptData, encryptData } from "../../utils/util_function";
import { Redirect2NewPage } from "../../Helpers/Redirect2";
import { COLORS } from "../../constants/ColorsData";

const CRMChoice: React.FC<PageGenerationProps> = ({ userid, setPageNameToLoad, creditValue, creditUpdater }) => {
    const expired_icon = "/images/icone/expired.png"
    const valid_icon = "/images/icone/valid.png"
    const hide_icon = "/images/btn/hide.png"
    const show_icon = "/images/btn/show.png"
    const first_image = "/images/7053216.png"

    const [showPass, setShowPass] = useState({
        Apollo: false,
        Hubspot: false,
        "Google Sheets": false
    })

    const [showInput, setShowInput] = useState(false)
    const [nameOfUser, setNameOfUser] = useState("")
    const [showSf_input, setShowSf_input] = useState({
        "pass": false,
        "api": false
    })
    const [configureCrm, setConfigureCrm] = useState(false)
    const [requestStatus, setRequestStatus] = useState({
        "Hubspot": false,
        "Google Sheets": false,
        "Salesforce": false,
        "Apollo": false
    })

    // Savoir si les données ont été déjà sauvegardés
    const [isRegistered, setIsRegistered] = useState({
        "Hubspot": false,
        "Google Sheets": false,
        "Salesforce": false,
        "Apollo": false
    })
    const [salesForceInputValue, setSalesForceInputValue] = useState({
        username: "",
        pass: "",
        token_user: ""
    })
    const [Message, setMessage] = useState("");
    const [crmType, setCrmType] = useState<CrmType>("Hubspot")

    const [crmApiKeyValue, setCrmApiKeyValue] = useState({
        "Hubspot": {
            key: "",
            validation: 0,
            stored: 0,
            fieldIsEmpty: true
        },
        "Google Sheets": {
            key: "",
            validation: 0,
            stored: 0,
            fieldIsEmpty: true
        },
        "Salesforce": {
            key: "",
            validation: 0,
            stored: 0,
            fieldIsEmpty: true
        },
        "Apollo": {
            key: "",
            validation: 0,
            stored: 0,
            fieldIsEmpty: true
        }
    })

    const keyVerificatorSameAsInStorage = (key_input_value: string) => {
        // Vérifie si la clé est exacte
        if (crmApiKeyValue[crmType].key === key_input_value) {
            // console.log(`La clé pour ${crmType} est valide.`);
            return true;
        } else {
            // console.log(`La clé pour ${crmType} est invalide.`);
            return false;
        }
    }

    const onInputChange = (val: React.ChangeEvent<HTMLInputElement>) => {
        const input_value = val.target.value
        const key_verify = keyVerificatorSameAsInStorage(input_value)

        switch (crmType) {
            case "Hubspot":
                setCrmApiKeyValue(prevs => ({ ...prevs, Hubspot: { key: input_value, validation: 0, stored: 0, fieldIsEmpty: false } }))
                setIsRegistered(prevs => ({
                    ...prevs,
                    Hubspot: key_verify
                }))
                break
            case "Google Sheets":
                setCrmApiKeyValue(prevs => ({ ...prevs, "Google Sheets": { key: input_value, validation: 0, stored: 0, fieldIsEmpty: false } }))
                setIsRegistered(prevs => ({
                    ...prevs,
                    "Google Sheets": key_verify
                }))
                break
            case "Salesforce":
                setCrmApiKeyValue(prevs => ({ ...prevs, Salesforce: { key: input_value, validation: 0, stored: 0, fieldIsEmpty: false } }))
                setIsRegistered(prevs => ({
                    ...prevs,
                    Salesforce: key_verify
                }))
                break
            case "Apollo":
                setCrmApiKeyValue(prevs => ({ ...prevs, Apollo: { key: input_value, validation: 0, stored: 0, fieldIsEmpty: false } }))
                setIsRegistered(prevs => ({
                    ...prevs,
                    Apollo: key_verify
                }))
                break
            default:
        }
    }

    const valueByKey = (key_word: "key" | "validation" | "stored", fieldCheckNumber?: number) => {
        // Champ input vide 1, 0 sinon
        // if(fieldCheckNumber === 1){
        //     return ""
        // }

        return crmApiKeyValue[crmType][key_word]
        // switch (crmType) {
        //     case "Hubspot":
        //         return crmApiKeyValue["Hubspot"].key
        //     case "Google Sheets":
        //         return crmApiKeyValue["Google Sheets"].key
        //     case "Salesforce":
        //         return crmApiKeyValue["Salesforce"].key
        //     default:
        //         return ""
        // }
    }

    const updateSf_UsernameInputValue = (val: React.ChangeEvent<HTMLInputElement>) => {
        setIsRegistered(prevs => ({
            ...prevs,
            Salesforce: val.target.value === salesForceInputValue.username ? true : false
        }))
        setSalesForceInputValue(
            usernamePrevValue => ({...usernamePrevValue, username: val.target.value})
        )
    }
    const updateSf_PassInputValue = (val: React.ChangeEvent<HTMLInputElement>) => {
        setIsRegistered(prevs => ({
            ...prevs,
            Salesforce: val.target.value === salesForceInputValue.pass ? true : false
        }))
        setSalesForceInputValue(
            passPrevValue => ({...passPrevValue, pass: val.target.value})
        )
    }
    const updateSf_TokenUserInputValue = (val: React.ChangeEvent<HTMLInputElement>) => {
        setIsRegistered(prevs => ({
            ...prevs,
            Salesforce: val.target.value === salesForceInputValue.token_user ? true : false
        }))
        setSalesForceInputValue(
            tokenUserPrevValue => ({...tokenUserPrevValue, token_user: val.target.value})
        )
    }

    // Display input field
    const onButtonClick = (status: boolean, title: CrmType) => {
        setMessage("")
        setShowInput(status)
        setCrmType(title)
        fillInputByCrmType(title)
    }

    /**
     * Récupère les informations déjà stockés pour afficher dans les champs
     * @param crm_name 
     */ 
    const fillInputByCrmType = (crm_name: CrmType) => {
        switch (crm_name) {
            case "Hubspot":
                if(localStorage.getItem(HS_CREDS_DATA)){
                    
                }
                break
            case "Apollo":
                if(localStorage.getItem(AP_CREDS_DATA)){

                }
                break
            case "Salesforce":
                if(localStorage.getItem(SF_CREDS_DATA)){
                    const decrypt_value_sf: string = decryptData(SF_CREDS_DATA)
                    if(decrypt_value_sf !== ""){
                        let sf_split_data = decrypt_value_sf.split("§§")

                        setSalesForceInputValue(
                            prev => ({...prev, 
                                username: sf_split_data[0],
                                pass: sf_split_data[1],
                                token_user: sf_split_data[2]
                            })
                        )

                        setIsRegistered(
                            regStatus => ({...regStatus, Salesforce: true})
                        )
                    }
                }
                break
            case "Google Sheets":
                if(localStorage.getItem(GS_CREDS_DATA)){
                    
                }
                break
            default:
                break
        }
    }

    const verifySalesforceKeyValidation = () => {
        if(!salesForceInputValue.username) {
            return
        }
        if(!salesForceInputValue.pass) return
        if(!salesForceInputValue.token_user) return
        
        const sf_data = [
            salesForceInputValue.username,
            salesForceInputValue.pass,
            salesForceInputValue.token_user
        ].join("§§")
        
        encryptData(SF_CREDS_DATA, sf_data)

        setIsRegistered(prevs => ({
            ...prevs,
            Salesforce: true
        }))

        // getDataFromSalesforce(crmApiKeyValue[crmType].key, "Contact")
        //     .then((rep: any) => {
        //         if (rep.data) {
        //             const data: any = rep.data.body
        //             // console.log("sf response => ", data)

        //             if (data.hasOwnProperty("errorCode")) {
        //                 let new_error_data: ErrorResponse = data
        //                 // alert(new_error_data.message)
        //                 setRequestStatus(prevs => ({ ...prevs, crmType: false }))
        //                 setMessage("Clé d'API invalide ou expirée")
        //             } else {
        //                 setCrmApiKeyValue(
        //                     prevs => ({
        //                         ...prevs,
        //                         Salesforce: {
        //                             key: crmApiKeyValue[crmType].key,
        //                             validation: data['success'],
        //                             stored: data['success'],
        //                             fieldIsEmpty: !data['success']
        //                         }
        //                     }))
        //                 localStorage.setItem(CRM_API_KEY, JSON.stringify(crmApiKeyValue))
        //                 setRequestStatus(prevs => ({ ...prevs, crmType: false }))
        //                 setMessage("Clé d'API valide")
        //             }
        //         }
        //     }).catch((e) => {
        //         // console.log("erreur: ", e)
        //         return false
        //     })
    }

    const verifyApolloKeyValidation = () => {
        if(crmApiKeyValue["Apollo"].fieldIsEmpty){
            return
        }
        setRequestStatus(prevs => ({ ...prevs, Apollo: true }))
        
        let temp_validation = false
        apolloApiVerification(crmApiKeyValue["Apollo"].key).then(
            (rep: any) => {
                if (rep.data) {
                    temp_validation = rep.data.is_logged_in;
                } else {
                    temp_validation = rep.is_logged_in
                }
                    
                setCrmApiKeyValue(prevs => ({
                    ...prevs,
                    Apollo: {
                        ...prevs.Apollo,
                        validation: Number(temp_validation),
                        stored: 1,
                        fieldIsEmpty: false
                    }
                }));
                setRequestStatus(prevs => ({ ...prevs, Apollo: false }));

                // Updating all values in crmApiKeyValue and storing in local storage
                const updatedCrmApiKeyValue = {
                    ...crmApiKeyValue,
                    Apollo: {
                        ...crmApiKeyValue.Apollo,
                        validation: Number(temp_validation),
                        stored: 1,
                        fieldIsEmpty: false
                    }
                };
                localStorage.setItem(CRM_API_KEY, JSON.stringify(updatedCrmApiKeyValue));
                setIsRegistered(prevs => ({
                    ...prevs,
                    Apollo: true
                }))
                setRequestStatus(prevs => ({ ...prevs, Apollo: false }))

            }).catch((e) => {
                console.error(e)
                setRequestStatus(prevs => ({ ...prevs, Apollo: false }))
            })
    }

    const verifyHubspotKeyValidation = () => {
        if(crmApiKeyValue["Hubspot"].fieldIsEmpty){
            return
        }
        setRequestStatus(prevs => ({ ...prevs, Hubspot: true }))

        let temp_validation = false

        hubspotApiVerification(crmApiKeyValue["Hubspot"].key).then(
            (rep: any) => {
                if (rep.data) {
                    temp_validation = rep.data.is_logged_in;
                }else {
                    temp_validation = rep.is_logged_in // false
                }

                setCrmApiKeyValue(prevs => ({
                    ...prevs,
                    Hubspot: {
                        ...prevs.Hubspot,
                        validation: Number(temp_validation),
                        stored: 1,
                        fieldIsEmpty: false
                    }
                }));
                setRequestStatus(prevs => ({ ...prevs, Hubspot: false }));

                // Updating all values in crmApiKeyValue and storing in local storage
                const updatedCrmApiKeyValue = {
                    ...crmApiKeyValue,
                    Hubspot: {
                        ...crmApiKeyValue.Hubspot,
                        validation: Number(temp_validation),
                        stored: 1,
                        fieldIsEmpty: false
                    }
                };
                localStorage.setItem(CRM_API_KEY, JSON.stringify(updatedCrmApiKeyValue));
                setIsRegistered(prevs => ({
                    ...prevs,
                    Hubspot: true
                }))
                setRequestStatus(prevs => ({ ...prevs, Hubspot: false }))
                
            }).catch((e) => {
                console.error(e)
                setRequestStatus(prevs => ({ ...prevs, Hubspot: false }))
            })

        // setTimeout(() => {
        //     // Updating all values in crmApiKeyValue and storing in local storage
        //     const updatedCrmApiKeyValue = {
        //         ...crmApiKeyValue,
        //         Hubspot: {
        //             ...crmApiKeyValue.Hubspot,
        //             validation: Number(true),
        //             stored: 1,
        //             fieldIsEmpty: false
        //         }
        //     };
        //     localStorage.setItem(CRM_API_KEY, JSON.stringify(updatedCrmApiKeyValue));

        //     setIsRegistered(prevs => ({
        //         ...prevs,
        //         Hubspot: true
        //     }))

        //     setRequestStatus(prevs => ({ ...prevs, Hubspot: false }))
        // }, 2000)
    }

    // Save to localstorage
    const saveToStorage = () => {
        setMessage("")

        switch (crmType) {
            case "Hubspot":
                verifyHubspotKeyValidation()
                break
            case "Apollo":
                verifyApolloKeyValidation()
                break
            case "Salesforce":
                verifySalesforceKeyValidation()
                break
            default:
                break
        }
    }

    const goNextStep = () => {
        // setPageNameToLoad("Destinataire")
        Redirect2NewPage("Aurélia", `Dashboard/Destinataire`)
    }

    const showOrHideInputValue = () => {
        switch(crmType) {
            case "Apollo":
                setShowPass(prevs => ({...prevs, Apollo: !prevs["Apollo"] }))
                break
            case "Hubspot":
                setShowPass(prevs => ({...prevs, Hubspot: !prevs["Hubspot"] }))
                break
            case "Google Sheets":
                setShowPass(prevs => ({...prevs, "Google Sheets": !prevs["Google Sheets"] }))
                break
            default:
                break
        }
    }

    useEffect(() => {
        const userInfoData = localStorage.getItem(USER_INFO_KEY)
        if(userInfoData) {
            const userInfoDataValue = JSON.parse(userInfoData)
            const firstname = userInfoDataValue['firstName']
            const lastname = userInfoDataValue['lastName']

            setNameOfUser(firstname + " " + lastname)
        }
    }, [])

    useEffect(() => {
        const ls_crm_api_key = localStorage.getItem(CRM_API_KEY)

        if (ls_crm_api_key) {
            const ls_crm_api_value = JSON.parse(ls_crm_api_key)
            setCrmApiKeyValue(ls_crm_api_value)
            setIsRegistered({
                "Hubspot": ls_crm_api_value['Hubspot']['validation'],
                "Google Sheets": ls_crm_api_value['Google Sheets']['validation'],
                "Salesforce": ls_crm_api_value['Salesforce']['validation'],
                "Apollo": ls_crm_api_value['Apollo']['validation']
            })
        }
    }, [])

    return (
        <>
            {
                !configureCrm ? (
                    <div className="crm-container" style={{ justifyContent: "center"}}>
                        <div className="crm-btn-main-container">
                            <h1 className="crm-welcome-title">Bienvenue {nameOfUser && nameOfUser}</h1>
                            <p className="crm-text-p">Aurélia CRM remplit et met à jour efficacement les informations des prospects cibles dans les plateformes de CRM.</p>
                            <div style={{ display: "flex" }}>
                                <div className="crm-btn-container">
                                    <button onClick={() => setConfigureCrm(true)} className="crm-btn-verification" >Configurer le(s) CRM</button>
                                </div>
                                <div className="crm-btn-container">
                                    <button onClick={goNextStep} className="crm-btn-verification">Passer cette étape</button>
                                </div>
                            </div>
                        </div>

                        <div className="crm-first-image">
                            <img src={first_image} alt="" width={350} height={350} />
                        </div>
                    </div>
                ) : (
                    <div className="crm-container">
                        <div className="crm-left-part">
                            <div className="crm-title">Choisissez votre CRM</div>
                            <div className="crm-btn-container">
                                <Imagebutton title="Hubspot" url="https://www.hubspot.fr/" model_name="Aurélia" showInputKey={onButtonClick} />
                                <Imagebutton title="Apollo" url="https://login.salesforce.com/" showInputKey={onButtonClick} />
                                <Imagebutton title="Salesforce" url="https://login.salesforce.com/" showInputKey={onButtonClick} />
                                <Imagebutton title="Google Sheets" url="https://docs.google.com/spreadsheets/" showInputKey={onButtonClick} disabled={true}/>
                            </div>
                        </div>

                        <div className="crm-right-part">
                            <div className="crm-access-container">
                                {
                                    showInput && (
                                        <>
                                            <label className="crm-title">Clé d'API pour {crmType} :</label>
                                            <div className="crm-row">
                                                <div className="crm-row-input-and-img">
                                                    {
                                                        crmType === "Salesforce" ? (
                                                            <div className="crm-input-container" style={{flexDirection: "column"}}>
                                                                <div className="crm-input-container">
                                                                    <div className="crm-input-container">
                                                                        <input 
                                                                            type="text" className={salesForceInputValue.username === "" ? "error-field input_text" : "no-error input_text"} 
                                                                            value={salesForceInputValue.username} 
                                                                            onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateSf_UsernameInputValue(val)} 
                                                                            placeholder="Nom d'utilisateur du compte Salesforce" style={{ width: '100%', marginBottom: 15 }} />
                                                                    </div>
                                                                    <div className="crm-img-container"></div>
                                                                </div>

                                                                <div className="crm-input-container">
                                                                    <div className="crm-input-container">
                                                                        <input 
                                                                            type={showSf_input['pass'] ? "text" : "password"} className={salesForceInputValue.pass === "" ? "error-field input_text" : "no-error input_text"} 
                                                                            value={salesForceInputValue.pass} 
                                                                            onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateSf_PassInputValue(val)}  
                                                                            placeholder="Mot de passe du compte Salesforce" style={{ width: '100%', marginBottom: 15 }} />
                                                                    </div>
                                                                    <div className="crm-img-container">
                                                                        <img src={showSf_input['pass'] ? show_icon : hide_icon} alt="" title={showSf_input['pass'] ? "Affiché" : "Caché"} width={40} height={40} onClick={() => setShowSf_input(prevs => ({...prevs, pass: !prevs.pass}))} style={{cursor: "pointer"}}/>
                                                                    </div>
                                                                </div>

                                                                <div className="crm-input-container">
                                                                    <div className="crm-input-container">
                                                                    <input 
                                                                        type={showSf_input['api'] ? "text" : "password"} className={salesForceInputValue.token_user === "" ? "error-field input_text" : "no-error input_text"} 
                                                                        value={salesForceInputValue.token_user} 
                                                                        onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateSf_TokenUserInputValue(val)} 
                                                                        placeholder="Clé d'API" style={{ width: '100%', marginBottom: 15 }} />
                                                                    </div>
                                                                    <div className="crm-img-container">
                                                                        <img src={showSf_input['api'] ? show_icon : hide_icon} alt="" title={showSf_input['api'] ? "Affiché" : "Caché"} width={40} height={40} onClick={() => setShowSf_input(prevs => ({...prevs, api: !prevs.api}))} style={{cursor: "pointer"}}/>
                                                                    </div>
                                                                    <div className="crm-img-container">
                                                                        <img src={Boolean(crmApiKeyValue[crmType].validation) ? valid_icon : expired_icon} alt="S" width={40} height={40} title={crmApiKeyValue[crmType].validation ? "Valide" : "Expiré"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="crm-input-container">
                                                                <div className="crm-input-container">
                                                                    <input type={showPass[crmType] ? "text" : "password"} className={crmApiKeyValue[crmType].fieldIsEmpty ? "error-field input_text" : "no-error input_text"} value={valueByKey("key", 0)} onChange={(val: React.ChangeEvent<HTMLInputElement>) => onInputChange(val)} placeholder="Veuillez insérer ici votre clé d'accès" style={{ width: '100%' }} />
                                                                </div>
                                                                <div className="crm-img-container">
                                                                    <img src={showPass[crmType] ? show_icon : hide_icon} alt="" title={showPass[crmType] ? "Affiché" : "Caché"} width={40} height={40} onClick={showOrHideInputValue} style={{cursor: "pointer"}}/>
                                                                </div>
                                                                <div className="crm-img-container">
                                                                    <img src={Boolean(crmApiKeyValue[crmType].validation) ? valid_icon : expired_icon} alt="S" width={40} height={40} title={crmApiKeyValue[crmType].validation ? "Valide" : "Expiré"} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                                {
                                                    requestStatus[crmType] ? (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <LoaderResponse colorValue={COLORS.jauneClaire} />
                                                            <label style={{ color: 'black' }}>Vérification en cours...</label>
                                                        </div>
                                                    ) : (
                                                        isRegistered[crmType] ?
                                                            <button 
                                                                onClick={saveToStorage} 
                                                                disabled={isRegistered[crmType]} 
                                                                style={{
                                                                    background: crmApiKeyValue[crmType].validation ? "green" : "#ffbc40",
                                                                    width: 145, height: 50
                                                                }} 
                                                                className="crm-btn-save"
                                                                >Suivant
                                                            </button>
                                                            : <button 
                                                                onClick={saveToStorage} 
                                                                className="crm-btn-verification" 
                                                                style={{ width: 145, height: 50}}
                                                                >{crmType === "Salesforce" ? "Vérifier et enregistrer": "Vérifier"}
                                                            </button>

                                                    )
                                                }
                                                <label className="crm-title" style={Boolean(crmApiKeyValue[crmType].validation) ? { color: 'green' } : { color: 'red' }}>{Message}</label>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default CRMChoice
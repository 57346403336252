import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";

import "../../styles/Campagne.css"
import PageGenerationProps from "../../interface/PageInterface";
import { ACCROCHE_KEY, CAMPAGN_LOAD_STATE, DEST_SAVING_STATE, DOC_ID_KEY, DOMAINE_CHECKBOX_KEY, EXCLUDE_LOCALISATION_KEY, FOLLOWUP_SAVING_STATE, FOLLOW_UP_DATA, INCITATION_KEY, INCLUDE_LOCALISATION_KEY, JOB_CHECKBOX_KEY, MAIL_CHECKBOX_KEY, MAIL_LANGUAGE_KEY, MAIL_TITLE_CHECKBOX_KEY, PROMPT2LLM_KEY, RECIPIENT_KEY, SENDER_KEY, SENDER_SAVING_STATE, TECHNOLOGY_KEY, TITRE_MAIL_KEY, USER_ID_KEY, USER_INFO_KEY, V2_MAIL_KEY, V2_RECIPIENT_KEY, V2_SENDER_KEY } from "../../constants/StoreKey";
import { MailHistDataType } from "../../interface/DataI";
import { getUserCampaignHistory } from "../../services/UserServices";
import { sendFrontEndData } from "../../api/connect2llm";
import { Redirect2NewPage } from "../../Helpers/Redirect2";
import { decryptData } from "../../utils/util_function";
import { getUserCreditPricingInfo } from "../../api/tarifs_api";
import { COLORS } from "../../constants/ColorsData";
import CampaignLauncher from "../../components/Cards/CampagnCard";
import { CAMPAGN_TEXT_VALIDATION, DASHBOARD_BASE_ROUTE, FIRST_PAGE, PRE_BASE_ROUTE } from "../../constants/textVariable";
import { useParams } from "react-router-dom";
import { navigate2 } from "../../utils/redirection";
import { LoaderResponse } from "../../components/Loader";
import WSnackbar from "../../components/Widget/WSnackbar";

const LLM_NAME = "Aurélia"

const CampaignConfig: React.FC<PageGenerationProps> = ({ creditValue, creditUpdater }) => {
    const rocket_image = "/images/btn/rocket-campagne.png"

    const params = useParams();
    
    const [userID, setID] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [totalCredit, setTotalCredit] = useState(creditValue)
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showTable, setShowTable] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [isSendingData2Aurelia, setIsSendingData2Aurelia] = useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loaderPage, setLoaderPage] = useState<boolean>(true); // Loader fonctionne à chaque validation
    const [emptyField, setEmptyField] = useState({
        state1: false
    })
    const [mailFieldNumber, setMailFieldNumber] = useState(totalCredit === 0 ? 0 : 1)

    // const [statusMailNumberAndText, setStatusMailNumberAndText] = useState<string>(String(mailFieldNumber).concat(" mail / ") + String(totalCredit).concat(totalCredit < 2 ? " crédit restant" : " crédits restants"));
    const [statusMailNumberAndText, setStatusMailNumberAndText] = useState<string>(" / " + String(totalCredit).concat(totalCredit < 2 ? " crédit restant" : " crédits restants"));
    const [modalInfo2Display, setModalInfo2Display] = useState({
        header_title: "Préparation du lancement de campagne",
        description: "Dans cette section, vous allez pouvoir spécifier le nombre d'email à envoyer avant la validation finale",
        buttonText: ["S'inscrire", "Valider & envoyer"],
        loading_text: ["Redirection en cours...", "Envoi en cours..."],
        hide_input_content: false
    })

    const closeModal = () => {
        setShowModal(!showModal)
        setMailFieldNumber(totalCredit === 0 ? 0 : 1)
        // setShowTable(!showTable)
    }
    const toggleModal = () => {
        const userInfoData = localStorage.getItem(USER_INFO_KEY)

        if (userInfoData) {
            var email = JSON.parse(userInfoData)['email']
        }

        getUserCreditPricingInfo(email)
            .then((rep) => {
                if (rep && rep.data && rep.data.body) {
                    const body: any = rep.data.body
                    const new_credit_count = body.credit_count
                    // const status_nb_mail_text = String(totalCredit === 0 ? 0 : 1).concat(" mail / ") + String(new_credit_count).concat(new_credit_count > 1 ? " crédits restants" : " crédit restant")
                    const status_nb_mail_text = "/ " + String(new_credit_count).concat(new_credit_count > 1 ? " crédits restants" : " crédit restant")

                    setTotalCredit(new_credit_count)
                    setStatusMailNumberAndText(status_nb_mail_text)
                    creditUpdater(new_credit_count)
                    if (new_credit_count === 0) setErrorMessage("Désolé, vous n'avez pas assez de crédit. Veuillez vous inscrire à partir de la page Tarifs")
                }
            })
            .catch((e) => { console.log("Credit verification error", e) })

        setShowModal(!showModal)
        setShowTable(false)
    }
    
    const goToNextPage = () => navigate2("CampaignHistory", userID, DASHBOARD_BASE_ROUTE)
    
    const validate = () => {
        if (mailFieldNumber === 0) {
            setEmptyField(prev => ({ ...prev, state1: true }))
            if (totalCredit === 0) setErrorMessage("Désolé, vous n'avez pas assez de crédit. Veuillez vous inscrire à partir de la page Tarifs")
            else setErrorMessage("Vous devez envoyer au moins un email")
            Redirect2NewPage("Aurélia", `Dashboard/Tarifs`)
        }
        else if (totalCredit === 0) {
            setErrorMessage("Désolé, vous n'avez pas assez de crédit. Veuillez vous inscrire à partir de la page Tarifs")
            Redirect2NewPage("Aurélia", `Dashboard/Tarifs`)
        } else {
            setErrorMessage("")
            setEmptyField(prev => ({ ...prev, state1: false }))
            setIsSendingData2Aurelia(true)
            setLoaderPage(true)

            // const prompt_base = localStorage.getItem(PROMPT2LLM_KEY)
            
            // if(prompt_base) console.log("Decoded prompt: ", decryptData(prompt_base))
            
            const recipient_data_v2 = localStorage.getItem(V2_RECIPIENT_KEY)
            const sender_data_v2 = localStorage.getItem(V2_SENDER_KEY)
            const mail_data_v2 = localStorage.getItem(V2_MAIL_KEY)
            if (recipient_data_v2 && sender_data_v2 && mail_data_v2) {
                let language = localStorage.getItem(MAIL_LANGUAGE_KEY)

                // const decode_prompt = decryptData(PROMPT2LLM_KEY).replace("mail_number_count", mailFieldNumber)

                const decoded_reciepient_data = decryptData(V2_RECIPIENT_KEY)
                const decoded_sender_data = decryptData(V2_SENDER_KEY)
                const decoded_mail_data = decryptData(V2_MAIL_KEY)
                const campaign = {
                    "campaign_number": mailFieldNumber
                }
                const decoded_followup_data = decryptData(FOLLOW_UP_DATA)
                
                const res = sendFrontEndData(
                    userID, 
                    LLM_NAME, 
                    language ? JSON.parse(language) : JSON.parse('{ "name": "Français","classname":"fr"}'), 
                    decoded_reciepient_data, 
                    decoded_sender_data, 
                    decoded_mail_data, 
                    campaign,
                    decoded_followup_data
                )

                res.then(() => {
                    setShowModal(!showModal);
                    // setShowTable(!showTable)
                    localStorage.removeItem(PROMPT2LLM_KEY)
                    localStorage.removeItem(MAIL_CHECKBOX_KEY)
                    localStorage.removeItem(INCLUDE_LOCALISATION_KEY)
                    localStorage.removeItem(EXCLUDE_LOCALISATION_KEY)
                    localStorage.removeItem(V2_RECIPIENT_KEY)
                    localStorage.removeItem(V2_SENDER_KEY)
                    localStorage.removeItem(V2_MAIL_KEY)

                    localStorage.removeItem(RECIPIENT_KEY)
                    // localStorage.removeItem(SENDER_KEY)
                    localStorage.removeItem(MAIL_CHECKBOX_KEY)
                    localStorage.removeItem(ACCROCHE_KEY)
                    localStorage.removeItem(TITRE_MAIL_KEY)
                    localStorage.removeItem(INCITATION_KEY)
                    localStorage.removeItem(DOC_ID_KEY)
                    localStorage.removeItem(DOMAINE_CHECKBOX_KEY)
                    localStorage.removeItem(JOB_CHECKBOX_KEY)
                    localStorage.removeItem(TECHNOLOGY_KEY)
                    localStorage.removeItem(MAIL_TITLE_CHECKBOX_KEY)

                    localStorage.removeItem(DEST_SAVING_STATE)
                    localStorage.removeItem(SENDER_SAVING_STATE)
                    localStorage.removeItem(FOLLOWUP_SAVING_STATE)
                    
                    localStorage.setItem(CAMPAGN_LOAD_STATE, CAMPAGN_TEXT_VALIDATION)
                    
                    setLoaderPage(false)
                    setIsSendingData2Aurelia(false)
                    Redirect2NewPage(LLM_NAME, "/info")
                    
                    setOpenSnackbar(true)

                }).catch((e) => {
                    console.error("Sending prompt error: ", e)
                })
            } else {
                setLoaderPage(false)
                setIsSendingData2Aurelia(false)
                
                setModalInfo2Display(prevs => ({
                    ...prevs,
                    description: "Apparemment vous n'avez pas encore compléter les formulaires !",
                    header_title: "Oops!",
                    buttonText: ["S'inscrire", "Commencer"],
                    loading_text: ["Redirection en cours...", "Nous allons vous rediriger..."],
                    hide_input_content: true
                }))
                
                setTimeout(() => {
                    navigate2(FIRST_PAGE, userID)
                }, 4000)
            }
        }
    }

    const updateMailNumberCount = (val: React.ChangeEvent<HTMLInputElement>) => {
        const field_value = Number(val.target.value)
        setMailFieldNumber(field_value)

        // const status_nb_mail_text = String(field_value).concat(field_value > 1 ? " mails / " : " mail / ") + String(totalCredit).concat(totalCredit > 1 ? " crédits restants" : " crédit restant")
        const status_nb_mail_text =  "/ " + String(totalCredit).concat(totalCredit > 1 ? " crédits restants" : " crédit restant")
        setStatusMailNumberAndText(status_nb_mail_text)
    }

    const childrenView = () => {
        return (
            <div className="campagne-modal-container">
                {/* <p style={{ textAlign: "center", fontSize: "larger" }}>{modalInfo2Display.description}</p> */}
                <p style={{ textAlign: "left", fontSize: "large" }}>Nombre d'emails à envoyer</p>
                {
                    <div className="campagne-modal-input-btn-mailnumber">
                        <input
                            type="number"
                            disabled={openSnackbar}
                            className={emptyField.state1 ? "error-field input_text" : "no-error input_text"}
                            min={totalCredit > 1 ? 1 : 0}
                            // max={totalCredit === 0 ? 0 : totalCredit >= 5 ? 5 : totalCredit}
                            max={totalCredit}
                            value={mailFieldNumber}
                            onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateMailNumberCount(val)}
                        />
                        <label style={{ fontWeight: "normal", color: "black" }}>{statusMailNumberAndText}</label>
                    </div>
                }
                <p style={{ color: "red", textAlign: "center", paddingLeft: 15, paddingRight: 15 }}>{errorMessage}</p>
            </div>
        )
    }

    // Vérifier si on a déjà configurer les informations sur le destinataire, l'émetteur, et le mail
    useEffect(() => {
        if(params.id) {
            if(localStorage.getItem(DEST_SAVING_STATE) === null){
                return navigate2("Destinataire", params.id)
            }else if(localStorage.getItem(SENDER_SAVING_STATE) === null){
                return navigate2("Emetteur", params.id)
            }else if(localStorage.getItem(PROMPT2LLM_KEY) === null){
                return navigate2("Mail", params.id)
            }else if(decryptData(FOLLOWUP_SAVING_STATE) === null){
                return navigate2("FollowUp", params.id)
            }
        }
    }, [])

    useEffect(() => {
        const userid = localStorage.getItem(USER_ID_KEY)

        if (userid) {
            setID(userid)
            getUserCampaignHistory(userid).then(
                (rep: any) => {
                    if (rep.body && rep.body.length > 0) {
                        let temp_data_arr: Array<MailHistDataType> = rep.body

                        temp_data_arr.forEach((val: MailHistDataType, index: number) => {
                            val['id'] = index
                            if (index % 2 === 0) val['status'] = "row1"; else val['status'] = "row2"
                        })
                    } else {
                        // console.log("Else: ", rep)
                    }
                    setRefreshing(false)
                }
            ).catch((e) => console.error("Campaign error: ", e))
        }
    }, [refreshing])

    useEffect(() => {
        setTimeout(() => {
            setLoaderPage(false)
        }, 2000); // Délai minimal
    }, [])
    
    
    return (
        <>
            {
                loaderPage ? (
                    <div className="campagne-container" style={{ justifyContent: "center", alignItems: "center" }}>
                        <LoaderResponse colorValue={"white"} w={130} h={130} />
                        <p style={{ color: "white" }}>{isSendingData2Aurelia ? "Envoi en cours" : "Chargement en cours..."}</p>
                    </div>
                ) : (
                    <div className="campagne-container scroll" style={{overflowY: "hidden"}}>
                        <div className="campagne-two-btn">
                            <CampaignLauncher
                                header_title="Configurez votre campagne"
                                onValid={validate}
                                loader_state={loaderPage}
                                >
                                <div>
                                    {childrenView()}
                                </div>
                            </CampaignLauncher>
                        </div>

                        <div className="campagne-second-part" style={{ background: showTable ? COLORS.gris : 'transparent' }}>
                            {
                                !showTable &&
                                <div style={{ display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="campagne-text" style={{justifyContent: "center", alignItems: "center"}}>
                                        <h1>Lancer une campagne </h1>
                                        <p>Vous permet de finaliser l'envoi d'email, en spécifiant une valeur pour la quantité à envoyer</p>
                                    </div>
                                </div>
                            }
                            { openSnackbar && <WSnackbar OPEN={openSnackbar} MESSAGE="Votre campagne a été lancée" redirectFunction={goToNextPage} TIME_DELAY={2000} />}
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default CampaignConfig
import React, { useEffect, useState } from 'react'

import "../../styles/Tarifs.css"
import "../../styles/Welcoming.css"
import { getUserCreditPricingInfo, saveUserSubscriptionData } from '../../api/tarifs_api'
import { USER_INFO_KEY } from '../../constants/StoreKey'
import { Redirect2NewPage } from '../../Helpers/Redirect2'
import { LoaderResponse } from '../../components/Loader'
import { UserSubsType } from '../../type/TarifsSubsType'
import { COLORS } from '../../constants/ColorsData'
import { navigate2 } from '../../utils/redirection'
import { FIRST_PAGE } from '../../constants/textVariable'

const WelcomePage = () => {
    const yellowcheckmark = "/images/icone/Check.svg"
    const userSubsVersionType: UserSubsType = "trial_version"
    const color = COLORS.jauneClaire

    const [subsRequest, setSubsRequest] = useState(false);
    const [subsVerificationRequest, setVerificationSubsRequest] = useState(true);
    
    const trialPreparation = () => {
        setSubsRequest(true)
        const ls_user_data = localStorage.getItem(USER_INFO_KEY)

        if(ls_user_data) {
            const ls_user_data_value = JSON.parse(ls_user_data)
            // Cette fonction sera exécutée une seule fois au démarrage du composant
            saveUserSubscriptionData({
                user_id: ls_user_data_value._id.$oid,
                email: ls_user_data_value.email,
                credit_count: 10,
                all_total_credit: 10,
                subscription_type: userSubsVersionType,
                trial_expiration : false
            }).then((rep: any) => {
                if(rep && rep.data) {
                    const body = rep.data.body
                    if(body.message_type === "success"){
                        navigate2(FIRST_PAGE, ls_user_data_value._id.$oid)
                    }else {
                        console.log("Insert error: ", body.message_content)
                    }
                    
                    setSubsRequest(false)
                }
            }).catch((e) => {
                setSubsRequest(false)
                console.error(e)
            })
        }
    }

    useEffect(() => {
        const userInfoData = localStorage.getItem(USER_INFO_KEY)
        var email = ""
        
        if(userInfoData) {
            const userid = JSON.parse(userInfoData)['_id'].$oid
            email = JSON.parse(userInfoData)['email']

            getUserCreditPricingInfo(email)
            .then((rep) => {
                if(rep && rep.data && rep.data.body) {
                    navigate2(FIRST_PAGE, userid)
                }else {
                    setTimeout(() => {
                        setVerificationSubsRequest(false)
                    }, 1500)
                }
            })
            .catch((e) => { console.log("Welcome: erreur credit pricing", e)})
        } else {
            Redirect2NewPage("login")
        }
    })

    return <>
        {
            !subsVerificationRequest ? (
                <div className='welcoming-main-page'>
                    <div className="welcome-left-container">
                        <div className='welcome-left-intro-container'>
                            <h1 className='welcome-text'>Bienvenue chez <p className='welcome-big-text'>AIris</p></h1>
                            <h2 style={{color: 'white', fontWeight: 'normal'}}>L’IA pour le marketing avec Aurélia IA</h2>
                        </div>

                        <div className='welcome-left-credit-container'>
                            <h3 className='welcome-begin'>Pour commencer, nous allons vous offrir : <b style={{color: COLORS.jauneClaire}}>10 crédits gratuits</b> afin de profiter des 4 modules d'Aurélia dont nous avons mis à disposition, comprenant :
                            </h3>
                        </div>

                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}></div>

                            <div className='trial-module-list-container'>
                                <div style={{display: 'flex', flex: 1}}>
                                    <div className='trial-module-list-img-container'>
                                        <img  src={yellowcheckmark} alt='' width={20} height={20} />
                                    </div> 
                                    <div className='trial-module-list-module-title-container'>
                                        <label className='trial-module-label'>Aurélia Search</label>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <div className='trial-module-list-img-container'>
                                        <img  src={yellowcheckmark} alt='' width={20} height={20} />
                                    </div> 
                                    <div className='trial-module-list-module-title-container'>
                                        <label className='trial-module-label'>Aurélia Contact</label>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <div className='trial-module-list-img-container'>
                                        <img  src={yellowcheckmark} alt='' width={20} height={20} />
                                    </div> 
                                    <div className='trial-module-list-module-title-container'>
                                        <label className='trial-module-label'>Aurélia LLM</label>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <div className='trial-module-list-img-container'>
                                        <img  src={yellowcheckmark} alt='' width={20} height={20} />
                                    </div> 
                                    <div className='trial-module-list-module-title-container'>
                                        <label className='trial-module-label'>Aurélia CRM</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display: "flex", height: 50}}></div>

                        <div className='welcome-left-btn-container'>
                            <button className='welcome-trial-btn' onClick={trialPreparation}>
                                {
                                    subsRequest ? (
                                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                            <LoaderResponse colorValue='white' w={15} h={15} />
                                            <p style={{color: 'white'}}>Validation en cours...</p>
                                        </div>
                                    ) : (
                                        "Essayer maintenant"
                                    )
                                }
                            </button>
                        </div>

                    </div>

                    <div style={{display: "flex", flex: 1}}>

                    </div>
                </div>
            ) : (
                <div style={{
                        display: 'flex', 
                        flex: 1, justifyContent: 'center', alignItems: 'center', 
                        height: '100vh', flexDirection: 
                        'column', 
                        backgroundImage: `url("/images/fond/sso_main_bg.jpg")`, backgroundRepeat: 'no-repeat', backgroundSize: "cover"
                    }}>
                    <LoaderResponse colorValue={color} w={130} h={130}/>
                    <p style={{color: color }}>Chargement en cours...</p>
                </div>
            )
        }
    </>
}

export default WelcomePage
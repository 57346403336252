import { useParams } from "react-router-dom";

import '../styles/Dash.css'

import { NavMenu } from "../components/NavMenu";
import AureliaPage from "./Aurelia/PageGeneration";
import { useEffect, useState } from "react";
import Sso from "./Sso";
import { getUserEmailDataFormatById, verifyUserExists } from "../services/UserServices";
import { DEST_SAVING_STATE, DOC_ID_KEY, FOLLOWUP_SAVING_STATE, MAIL_CHECKBOX_KEY, PROMPT2LLM_KEY, RECIPIENT_KEY, SENDER_KEY, SENDER_SAVING_STATE, USER_ID_KEY, USER_INFO_KEY } from "../constants/StoreKey";
import { getUserCreditPricingInfo } from "../api/tarifs_api";
import { verifyUserTokenValidation } from "../api/token_api";
import { Redirect2NewPage } from "../Helpers/Redirect2";
import { NAV_MENU, NAV_MENU_TITLE } from "../constants/ArrayData";
import DropDownMenu from "../components/DropdownMenu";
import ModalPage from "../components/Modal";
import { COLORS } from "../constants/ColorsData";
import { ModeSubsType } from "../type/TarifsSubsType";
import { NavSubMenu } from "../components/NavSubMenu";
import { FIRST_PAGE, PRE_BASE_ROUTE } from "../constants/textVariable";
import { decryptData } from "../utils/util_function";

interface IDash {
    menuName?: string;
}

type pageDataType = {
    parent_menu_name: string;
    page_name: string;
    page_desc: string;
}

const pricing_page_name = "Tarifs"


const Dash: React.FC<IDash> = ({menuName}) => {    
    const dest_icon = "/images/icone/left_menu/Destinataire.svg"
    const sender_icon = "/images/icone/left_menu/Emetteur.svg"
    const mail = "/images/icone/left_menu/Mail.svg"
    const crm = "/images/icone/left_menu/CRM.svg"
    const mail_followup = "/images/icone/left_menu/Followup.svg"
    const engrenage = "/images/icone/left_menu/Engrenage.svg"
    const dashboard = "/images/icone/left_menu/dashboard.svg"
    const campagne = "/images/icone/left_menu/Campagnes.svg"
    const tarif = "/images/icone/left_menu/Tarifs.svg"
    const alert_failed_icon = "/images/btn/cancel-btn.png"

    const user_id = String(localStorage.getItem(USER_ID_KEY))
    const userInfoData = localStorage.getItem(USER_INFO_KEY)

    const [mode, setMode] = useState<ModeSubsType> ("default")

    const params = useParams();
    
    const page_name = params.page_name
    const id_params = params.id
    const year = new Date().getFullYear()

    const [loadingState, setLoadingState] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false);
    const [beginCampagn, setBeginCampagn] = useState<boolean>(false);
    const [subMenuDisable, setSubMenuDisable] = useState({
        sender: true,
        mail: true,
        followup: true,
        campagn: true
    });

    const [currentPageName, setCurrentPageName] = useState<string>("")
    const [currentPageDesc, setCurrentPageDesc] = useState<string>("")
    const [nameUser, setNameUser] = useState<string>("")

    const [credit, setCredit] = useState<number>(0)

    const updatePageName = (data: pageDataType) => {
        if (data.parent_menu_name) {
            Redirect2NewPage("Aurélia", `${data.parent_menu_name}/${data.page_name}`)
        } else Redirect2NewPage("Aurélia", `Dashboard/${data.page_name}`)
    }

    const updateUserAvailableCredit = (cred: number) => {
        setLoadingState(true)
        setCredit(cred)
    }

    const changeCurrentPageName = (name: string) => {
        setCurrentPageName(name)
    }

    const CreditAlert = () => {
        return (
            <div className="dash-alert-container">
                <img className="dash-alert-img" src={alert_failed_icon} alt="" width={50} height={50} />
                <h1 id="dash-alert-title">Oops. Crédit est insuffisant !</h1>
                <p className="dash-alert-p">
                    Veuillez le recharger afin de continuer à profiter les services d'Aurélia
                </p>
            </div>
        )
    }

    const modalValidation = () => {
        if(currentPageName !== pricing_page_name) Redirect2NewPage("Aurélia", "Dashboard/Tarifs")
        else setShowModal(false)
    }

    const formatNumber = (n: number): string => {
        if (n < 1000) {
            return n.toString();
        } else if (n >= 1000 && n < 10000) {
            return `${(n / 1000).toFixed(1)} K`;
        } else if (n >= 10000 && n < 100000) {
            return `${Math.round(n / 1000)} K`;
        } else {
            return n.toString();
        }
    }

    useEffect(() => {
        if(user_id){
            var email = ""

            if(user_id !== id_params){
                window.location.replace('/')
                return
            }
            
            // IL FAUT AJOUTER DANS LA LISTE POUR QUE CA MARCHE
            var menu_nav_title = NAV_MENU_TITLE
            // menu_nav_title.push("CampaignHistory")
            
            if(page_name === String(undefined) || !menu_nav_title.includes(String(page_name))){
                window.location.replace(PRE_BASE_ROUTE + '/' + FIRST_PAGE + "/" + user_id)
                return
            }

            if(userInfoData) {
                email = JSON.parse(userInfoData)['email']
                setNameUser(JSON.parse(userInfoData)['firstName'] + " " + JSON.parse(userInfoData)['lastName'])
            }
            
            if(email !== ""){
                verifyUserTokenValidation().then((res_data: any) => {
                    // Token est valide
                    if(email && res_data && res_data.data && (res_data.data.status === "valid")){
                        getUserCreditPricingInfo(email)
                        .then((rep) => {
                            if(rep && rep.data && rep.data.body) {
                                const body: any = rep.data.body
                                setCredit(body.credit_count)
                                setLoadingState(true)

                                if(body.credit_count === 0) {
                                    setShowModal(true)
                                    setMode("restricted")
                                }else {
                                    setMode("normal")
                                }
                            }
                            else {
                                Redirect2NewPage("welcoming")
                            }
    
                            if(page_name) {
                                setCurrentPageName(page_name)
                                setCurrentPageDesc(NAV_MENU[page_name])
                            }
                        })
                        .catch((e) => { console.log(e)})
                    }
                    // Sinon, redirection vers login
                    else {
                        window.localStorage.removeItem(USER_ID_KEY)
                        window.localStorage.removeItem('token')
                        Redirect2NewPage("login")
                    }
                    
                }).catch((e) => console.error(e))
            }
        }

        // activer l'affichage des sous menu
        if(page_name !== "CRM" && page_name !== "Campagnes") {
            setBeginCampagn(true)

            let recipient_data_retrieve = localStorage.getItem(DEST_SAVING_STATE)
            let sender_data_retrieve = localStorage.getItem(SENDER_SAVING_STATE)
            let mail_data_retrieve = localStorage.getItem(PROMPT2LLM_KEY)
            let followup_data_retrieve = localStorage.getItem(FOLLOWUP_SAVING_STATE)
        
            // Etape 1 remplie, on désactive l'étape 2
            if(recipient_data_retrieve) {
                setSubMenuDisable(prevState => ({...prevState, sender: false}))
            }
            // Etape 1, 2 remplies, on désactive l'étape 3
            if(recipient_data_retrieve && sender_data_retrieve) {
                setSubMenuDisable(prevState => ({...prevState, mail: false}))
            }
            // Etape 1, 2, 3 remplies, on désactive l'étape 4
            if(recipient_data_retrieve && sender_data_retrieve && mail_data_retrieve) {
                setSubMenuDisable(prevState => ({...prevState, followup: false}))
            }
            // Etape 1, 2, 3, 4 remplies, on désactive l'étape 5
            if(recipient_data_retrieve && sender_data_retrieve && mail_data_retrieve && followup_data_retrieve) {
                setSubMenuDisable(prevState => ({...prevState, campagn: false}))
            }
        }
    }, [user_id, id_params, page_name, userInfoData])

    useEffect(() => {
        const doc_id = localStorage.getItem(DOC_ID_KEY)

        // if(doc_id){
            verifyUserExists(user_id).then(
                (rep) => {
                    if(rep.body === null || rep.body.user_status === "Not exist"){
                        localStorage.removeItem(USER_ID_KEY)
                        localStorage.removeItem('token')
                        window.location.replace('/login')
                    }else{
                        // Récupérer les informations à envoyer en prompt vers Aurélia : (stockage temporaire)
                        if(doc_id){
                            getUserEmailDataFormatById(doc_id).then(
                                (data_resp: any) => {
                                    if(data_resp.body){
                                        // const id_from_db = data_resp.body.user_id
                                        const recipient = data_resp.body.recipient
                                        const sender = data_resp.body.sender
                                        const mail = data_resp.body.mail
        
                                        if(recipient && Object.keys(recipient).length > 0) localStorage.setItem(RECIPIENT_KEY, JSON.stringify(recipient)); else localStorage.removeItem(RECIPIENT_KEY)
                                        if(sender && Object.keys(sender).length > 0) localStorage.setItem(SENDER_KEY, JSON.stringify(sender)); //else localStorage.removeItem(SENDER_KEY)
                                        if(mail && Object.keys(mail).length > 0) localStorage.setItem(MAIL_CHECKBOX_KEY, JSON.stringify(mail)); else localStorage.removeItem(MAIL_CHECKBOX_KEY)
                                    }
                                }
                            )
                        }
                    }
                }
            ).catch((e) => console.error(e))
        // } 
        // else {
        //     verifyUserExists(user_id).then(
        //         (rep) => {
        //             if(rep.body === null || rep.body.user_status === "Not exist"){
        //                 localStorage.removeItem(USER_ID_KEY)
        //                 localStorage.removeItem('token')
        //                 window.location.replace('/login')
        //             }
        //         }
        //     ).catch((e) => console.error(e))
        // }
    }, [id_params, user_id])

    // 
    if(user_id === null || user_id === undefined) {
        window.location.replace("/login")
        return <Sso />
    }
    
    return (
        <div className="dash-main-container">
            <section className="dash-left-part">
                {
                    mode === "restricted" ? (
                        <div className="dash-wrapper" 
                            // style={{ backgroundImage: `url(${bg_image})`, backgroundSize: 'cover' }}
                            >
                            {/* <DropDownMenu title={nameUser} MenuListElement={[]} /> */}
                            <div style={{padding: 10}}></div>
                            <NavMenu jsonData={{}} url="" desc_name="Bienvenue sur le choix de nos tarifs actuels" activeMenu={currentPageName} icon={tarif} title={pricing_page_name} menuIsClicked={updatePageName} />
                        </div>
                    ) : mode === "normal" ? (
                        <div className="dash-wrapper">
                            {/* <DropDownMenu title={nameUser} MenuListElement={[]} /> */}
                            <div style={{padding: 10}}></div>
                            
                            <div id="Campagn-menu">
                                <NavMenu jsonData={{}} url="" desc_name="Préparation de la campagne" activeMenu={currentPageName} icon={campagne} title="Campagne" menuIsClicked={updatePageName} />
                                {
                                    beginCampagn && (
                                        <div id="Campagn-submenu">
                                            <NavSubMenu sub_parent_menu_name="Campaign" sub_disable={false} sub_jsonData={{}} sub_url="" sub_desc_name="Informations sur la cible" sub_activeMenu={currentPageName} sub_icon={dest_icon} sub_title="Destinataire" sub_menuIsClicked={updatePageName} sub_menuTitle="1. Destinataire"/>
                                            <NavSubMenu sub_parent_menu_name="Campaign" sub_disable={subMenuDisable.sender} sub_jsonData={{}} sub_url="" sub_desc_name="Vos informations" sub_activeMenu={currentPageName} sub_icon={sender_icon} sub_title="Emetteur" sub_menuIsClicked={updatePageName} sub_menuTitle="2. Emetteur"/>
                                            <NavSubMenu sub_parent_menu_name="Campaign" sub_disable={subMenuDisable.mail} sub_jsonData={{}} sub_url="" sub_desc_name="Le format de mail" sub_activeMenu={currentPageName} sub_icon={mail} sub_title="Mail" sub_menuIsClicked={updatePageName} sub_menuTitle="3. Mail"/>
                                            <NavSubMenu sub_parent_menu_name="Campaign" sub_disable={subMenuDisable.followup} sub_jsonData={{}} sub_url="" sub_desc_name="Configurez votre follow up" sub_activeMenu={currentPageName} sub_icon={mail_followup} sub_title="FollowUp" sub_menuIsClicked={updatePageName} sub_menuTitle="4. Follow Up" />
                                            <NavSubMenu sub_parent_menu_name="Campaign" sub_disable={subMenuDisable.campagn} sub_jsonData={{}} sub_url="" sub_desc_name="Configurez ou suivez vos campagnes" sub_activeMenu={currentPageName} sub_icon={engrenage} sub_title="CampaignConfig" sub_menuIsClicked={updatePageName} sub_menuTitle="5. Configuration" />
                                        </div>
                                    )
                                }
                            </div>
                            {/* <NavMenu jsonData={{}} url="" desc_name="Suivez vos campagnes" activeMenu={currentPageName} icon={dashboard} title="Suivi" menuIsClicked={updatePageName} menuTitle="Dashboard" /> */}
                            <NavMenu jsonData={{}} url="" desc_name="Historique de campagne" activeMenu={currentPageName} icon={dashboard} title="CampaignHistory" menuIsClicked={updatePageName} menuTitle="Historique" />
                            <NavMenu isDisabled={true} jsonData={{}} url="" desc_name="Configuration du CRM" activeMenu={currentPageName} icon={crm} title="CRM" menuIsClicked={updatePageName} />
                            <NavMenu isDisabled={true} jsonData={{}} url="" desc_name="Bienvenue sur le choix de nos tarifs actuels" activeMenu={currentPageName} icon={tarif} title={pricing_page_name} menuIsClicked={updatePageName} />
                        </div>
                    ) : (
                        <div className="dash-wrapper">
                            {/* <DropDownMenu title={nameUser} MenuListElement={[]} /> */}
                            <div style={{padding: 10}}></div>
                        </div>
                    )
                }
                <div style={{display: "flex", flex: 1, flexDirection: 'column'}}>
                    <div id="credit-restant"><b>Crédits restants : </b><p id="credit-value">{loadingState && formatNumber(credit)}</p></div>
                    <footer className='left-part-footer'>&copy; Aurélia {year}. Tous droits réservés.</footer>
                </div>
            </section>

            <section className={page_name === "CampaignHistory" ? "dash-right-part-campagn-history" : "dash-right-part"} style={
                    page_name === "Suivi" || page_name === "CampaignConfig" || page_name === "Tarifs" ? {background: "transparent", color: "white"}
                    : {background: "white", color: "black"}
                }>
                <div className="dash-right-part-title">{currentPageDesc}</div>
                <div id="dash-form-right-content">
                    <div style={{display: 'flex', flex: 4, flexDirection : 'column', width: '100%'}}>
                        {loadingState && <AureliaPage userid={user_id} page_name={currentPageName} creditValue={credit} creditUpdater={updateUserAvailableCredit} setPageNameToLoad={changeCurrentPageName}/> }
                    </div>
                </div>
            </section>
            {
                loadingState && currentPageName !== pricing_page_name && showModal && (
                    <ModalPage 
                        header_title={""} 
                        header_title_bg_color={COLORS.rouge}
                        buttonText="S'abonner"
                        buttonBgColor="black"
                        loading_text="Redirection en cours"
                        loader_color="black"
                        open={showModal} 
                        onClose={modalValidation}
                        onValid={modalValidation}
                        children={CreditAlert()}  
                    />
                )
            }
        </div>
    )
}

export default Dash

import axios from 'axios';
import { MODEL_TITLE, PYTHON_API_URL, REAL_MODEL_NAME, REAL_MODEL_NAME2 } from '../constants/ArrayData';
import { IFollowUp } from '../interface/DataI';
import { EventUserDataToSend } from '../type/UserDataEvent';

const AxiosInstanceCreator = (BASE_URL: string) => {
  // Configuration globale d'Axios
  const instance = axios.create({
    baseURL: BASE_URL, // Remplacez par votre URL de base
    timeout: 60000, // Durée maximale d'attente pour la réponse en millisecondes
    headers: {
      'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
      // Vous pouvez ajouter d'autres en-têtes ici si nécessaire
    },
  });

  // Intercepteur pour les requêtes
  instance.interceptors.request.use(
    (config) => {
      // Vous pouvez ajouter des manipulations avant l'envoi de la requête ici
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Intercepteur pour les réponses
  instance.interceptors.response.use(
    (response) => {
      // Vous pouvez ajouter des manipulations avant de renvoyer la réponse ici
      return response;
    },
    (error) => {
      // Vous pouvez gérer les erreurs ici
      return Promise.reject(error);
    }
  );
  
  return instance;
}

export const getConversationHistoryById = async (user_id: string, model_name: string) => {
  const model_for_uri = PYTHON_API_URL[MODEL_TITLE.indexOf(model_name)] + "/retrieveConvHist/" + user_id
  
  try {
      return await axios({
        url: `${model_for_uri}`,
        method: 'GET',
        headers: {
          'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      })
    } catch (error) {
      // console.error(error)
      return {}
    }
};

export const sendPromptToLLM = async (user_id: string, user_prompt: string, model_name: string | null, language: string = "Français") => {
    const model_for_uri = REAL_MODEL_NAME[MODEL_TITLE.indexOf(String(model_name))]
    const data = {
        _id: user_id,
        prompt: user_prompt,
        language: language
    }
    
    try{
      const instance = AxiosInstanceCreator(model_for_uri);
      const response = await instance.post('', data);
      return response
    } catch(error) {
        // console.error(error)
        return ""
    }
}

export const sendFrontEndData = async (
  user_id: string, 
  model_name: string | null,
  language: string = "Français",
  recipient : any,
  sender: any,
  mail: any,
  campaign: any,
  followup: IFollowUp
) => {
    const model_for_uri = REAL_MODEL_NAME2[MODEL_TITLE.indexOf(String(model_name))]
    const data = {
        _id: user_id,
        language: language,
        recipient: recipient,
        sender: sender,
        mail: mail,
        campaign: campaign,
        followup: followup
    }
    
    try{
      const instance = AxiosInstanceCreator(model_for_uri);
      const response = await instance.post('', data);
      return response
    } catch(error) {
        // console.error(error)
        return ""
    }
}

export const checkLLMServerStatus = async (model_name: string | null) => {
  const model_for_uri = PYTHON_API_URL[MODEL_TITLE.indexOf(String(model_name))] + "/llm_status"
  
  try {
      await axios({
        url: `${model_for_uri}`,
        method: 'GET',
        headers: {
          'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      })
      return {"status": "active"}
    } catch (error) {
      // console.error(error)
      return {"status": "inactive"}
    }
};

export const wsLLMStatusCheck = async (model_name: string | null): Promise<any> => {
  return new Promise((resolve, reject) => {
    const ws = new WebSocket('wss://ia-generative.giris.fr:8443/ws');

      ws.onopen = () => {
          ws.send('Check LLM Server Status');  // Envoyer une requête au serveur WebSocket
      };

      ws.onmessage = (event: any) => {
          const data = JSON.parse(event.data);
          console.log('LLM Server Status:', data.status);
          ws.close();  // Fermer la connexion WebSocket après avoir reçu la réponse
          resolve(data); // Renvoyer les données via la promesse
        };

        ws.onerror = (error: Event) => {
            reject(error); // Rejeter la promesse en cas d'erreur
        };
      });
}
export const getLLMVersion = async (model_name: string | null) => {
  const model_for_uri = PYTHON_API_URL[MODEL_TITLE.indexOf(String(model_name))] + "/llm_version"
  
  try {
      return await axios({
        url: `${model_for_uri}`,
        method: 'GET',
        headers: {
          'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      })
    } catch (error) {
      console.error(error)
      return ""
    }
};

export const requestLLMService = async (model_name: string | null) => {
  const model_for_uri = process.env.REACT_APP_FASTSTART_URL + "/run_llm_service/" + model_name
  
  try {
      return await axios({
        url: `${model_for_uri}`,
        method: 'POST',
        headers: {
          'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
        }
      })
    } catch (error) {
      // console.error(error)
      return ""
    }
};

export const saveUserDataForMeeting = async (model_name: string | null, data2send : EventUserDataToSend) => {
  const model_for_uri = PYTHON_API_URL[MODEL_TITLE.indexOf(String(model_name))] + "/saveUser4Meeting"
  
  try {
      return await axios({
        url: `${model_for_uri}`,
        method: 'POST',
        headers: {
          'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
        }, data : data2send
      })
    } catch (error) {
      // console.error(error)
      return ""
    }
};

export const getUserInfo4MeetingById = async (user_id: string, model_name: string) => {
  const model_for_uri = PYTHON_API_URL[MODEL_TITLE.indexOf(model_name)] + "/retrieveUserD4Meeting/" + user_id
  
  try {
      return await axios({
        url: `${model_for_uri}`,
        method: 'GET',
        headers: {
          'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      })
    } catch (error) {
      // console.error(error)
      return {}
    }
};

export const runMeetingEvent = async (model_name: string, data2send : EventUserDataToSend) => {
  const model_for_uri = PYTHON_API_URL[MODEL_TITLE.indexOf(String(model_name))] + "/scheduleEventAndUpdate"
  
  try {
      return await axios({
        url: `${model_for_uri}`,
        method: 'POST',
        headers: {
          'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
        }, data : data2send
      })
    } catch (error) {
      // console.error(error)
      return ""
    }
};